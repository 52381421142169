function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
import { isEmpty } from 'lodash';
import { ts } from 'Shared/resources/assets/app/js/helpers/i18nHelpers';
import { alert, error } from 'Shared/resources/assets/app/js/helpers/notificationHelpers';
import { getTopWindowDocument } from 'Shared/resources/assets/app/js/helpers/windowHelpers';
import { getCsrfToken, setCsrfToken } from 'Shared/resources/assets/app/js/utils/csrfToken';
var Ajax = {
    /**
     * Timeout in MS after which all requests will abort.
     */ TIMEOUT: 60000,
    /**
     * Contains the last response object.
     */ response: null,
    /**
     * Resets the response to default.
     */ resetResponse: function resetResponse() {
        this.response = {
            response: null,
            successful: true,
            reloadPage: false,
            errorCode: null,
            errorMessages: []
        };
    },
    /**
     * Make an AJAX call using a GET request.
     *
     * @param {string}   url        Do not provide base URL or prefixing slash.
     * @param {function} [callback] Will be called after request is finished, must accept exactly one parameter
     *                              which will be populated with the Ajax.response object.
     * @param {object}   [settings] Will be handed to jQuery $.ajax.
     *
     * @param errorCallback
     * @returns {jqXHR} jqXHR object
     */ get: function get(url, callback, settings, errorCallback, alwaysCallback) {
        return this.send('GET', url, settings, callback, errorCallback, alwaysCallback);
    },
    /**
     * Make an AJAX call using a POST request.
     *
     * @param {string}   url        Do not provide base URL or prefixing slash.
     * @param {object}   data       The actual post data.
     * @param {function} [callback] Will be called after request is finished, must accept exactly one parameter
     *                              which will be populated with the Ajax.response object.
     * @param {object}   [settings] Will be handed to jQuery $.ajax.
     *
     * @param errorCallback
     * @param alwaysCallback
     * @returns {jqXHR} jqXHR object
     */ post: function post(url, data, callback, settings, errorCallback, alwaysCallback) {
        return this.send('POST', url, settings, callback, data, errorCallback, alwaysCallback);
    },
    /**
     * Make an AJAX call using a PUT request.
     *
     * @param url
     * @param data
     * @param callback
     * @param settings
     * @param errorCallback
     * @param alwaysCallback
     * @return {jqXHR}
     */ put: function put(url, data, callback, settings, errorCallback, alwaysCallback) {
        return this.send('PUT', url, settings, callback, data, errorCallback, alwaysCallback);
    },
    /**
     * Make an AJAX call using a DELETE request.
     *
     * @param url
     * @param callback
     * @param settings
     * @param errorCallback
     * @param alwaysCallback
     * @return {jqXHR}
     */ delete: function _delete(url, callback, settings, errorCallback, alwaysCallback) {
        return this.send('DELETE', url, settings, callback, errorCallback, alwaysCallback);
    },
    /**
     * Performs the actual AJAX request
     *
     * @param {string}   type     Request type, POST or GET.
     * @param {string}   url      Do not provide base URL but prefixing slash.
     * @param {object}   settings Will be handed to jQuery $.ajax.
     * @param {function} callback Will be called after request is finished, must accept exactly one parameter
     *                            which will be populated with the Ajax.response object.
     * @param {object}   [data]   Data to be sent, this only applies to POST requests.
     *
     * @param errorCallback
     * @param alwaysCallback
     * @returns {jqXHR} jqXHR object
     */ send: function send(type, url, settings, callback, data, errorCallback, alwaysCallback) {
        var _this = this;
        data = data || null;
        this.resetResponse();
        var ajaxConfig = _object_spread({
            url: url,
            type: type,
            timeout: this.TIMEOUT,
            dataType: 'json',
            headers: {
                'X-CSRF-TOKEN': getCsrfToken()
            }
        }, settings || {});
        if (data) {
            ajaxConfig.data = data;
        }
        // Construct and send request
        // Construct response with data
        return getTopWindowDocument().$.ajax(ajaxConfig).done(function(data) {
            _this.response.successful = data.successful;
            _this.response.response = data.response;
            _this.response.errorMessages = data.errorMessages;
            _this.response.reloadPage = data.reloadPage;
            if (data.customErrorMessage) {
                _this.response.customErrorMessage = data.customErrorMessage;
            }
        // If request failed, update response accordingly
        }).fail(function(jqXhr, textStatus, errorThrown) {
            _this.response.errorResponse = jqXhr.responseJSON;
            // Requests can fail without an actual error having occurred
            // For example if the request was aborted by the user or application
            if (textStatus === 'abort' || isEmpty(errorThrown)) {
                _this.response.successful = true;
                _this.response.response = null;
            } else {
                _this.response.successful = false;
                _this.response.errorCode = jqXhr.status;
                _this.response.errorMessages.push(textStatus);
                if (textStatus === 'timeout') {
                    _this.response.errorCode = 408; // HTTP timeout status code
                }
            }
        })// Finally, evaluate the response
        .always(function() {
            setCsrfToken(_this.response.csrfToken);
            // If the response was successful, we can still have errors in application layer
            // The callback must only be executed if there are no errors at all
            if (_this.response.successful) {
                if (_this.response.reloadPage === true) {
                    _this.reloadPage();
                    return;
                }
                if (_this.response.response) {
                    if (_this.response.response.pricingPlan !== undefined) {
                        _this.showPricingPlanAlert();
                        return;
                    }
                    if (_this.response.response.security !== undefined) {
                        _this.showSecurityAlert();
                        return;
                    }
                }
                if (typeof callback === 'function') {
                    callback(_this.response);
                }
            } else if (!_this.response.successful) {
                if (typeof errorCallback === 'function') {
                    errorCallback(_this.response);
                    return;
                }
                var message;
                switch(_this.response.errorCode){
                    case 408:
                        message = ts('A request has timed out. Please try to use a different configuration or contact support.');
                        break;
                    case 419:
                        return window.top.document.location.reload();
                    case 500:
                        message = ts('A server error occurred. Please contact support.');
                        break;
                    default:
                        if (_this.response.customErrorMessage) {
                            message = _this.response.customErrorMessage;
                        } else {
                            message = ts('An error with code %1 occurred. If this error persists, please contact support.', [
                                _this.response.errorCode
                            ]);
                        }
                }
                alert(message);
            }
            if (typeof alwaysCallback === 'function') {
                /**
                         * If you specify errorCallback, then the standard alert with an error is not displayed.
                         * alwaysCallback does not suppress an alert with an error.
                         */ alwaysCallback(_this.response);
            }
        });
    },
    /**
     * Reloads the page.
     *
     * Depending on the provided configuration, a notification is shown before the reload.
     */ reloadPage: function reloadPage() {
        if (this.response.response.hasOwnProperty('beforeReloadMessage')) {
            alert(this.response.response.beforeReloadMessage, function() {
                getTopWindowDocument().location.reload();
            });
        } else {
            getTopWindowDocument().location.reload();
        }
    },
    /**
     * Show the pricing plan alert.
     *
     * This usually means that a volume has been exceeded or a feature has been disabled.
     */ showPricingPlanAlert: function showPricingPlanAlert() {
        var message;
        if (this.response.response.pricingPlan.hasOwnProperty('volumeAccess')) {
            message = ts('Your %1 volume has been exceeded.', [
                ts(this.response.response.pricingPlan.volumeName)
            ]);
            message += ' ' + ts('This feature is currently not available. Please contact your administrator.');
        } else {
            message = ts('The requested feature or module is not enabled.');
        }
        error(message);
    },
    /**
     * Show the security alert.
     *
     * This usually means the user tried to call a URL he doesn't have permissions to.
     */ showSecurityAlert: function showSecurityAlert() {
        var message = "You don't have permissions to access the requested feature.";
        if (typeof this.response.response.security === 'string') {
            message = this.response.response.security;
        }
        error(ts(message));
    }
};
export { Ajax };
