function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_with_holes(arr) {
    if (Array.isArray(arr)) return arr;
}
function _array_without_holes(arr) {
    if (Array.isArray(arr)) return _array_like_to_array(arr);
}
function _iterable_to_array(iter) {
    if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _iterable_to_array_limit(arr, i) {
    var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];
    if (_i == null) return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _s, _e;
    try {
        for(_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true){
            _arr.push(_s.value);
            if (i && _arr.length === i) break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally{
        try {
            if (!_n && _i["return"] != null) _i["return"]();
        } finally{
            if (_d) throw _e;
        }
    }
    return _arr;
}
function _non_iterable_rest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _non_iterable_spread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _sliced_to_array(arr, i) {
    return _array_with_holes(arr) || _iterable_to_array_limit(arr, i) || _unsupported_iterable_to_array(arr, i) || _non_iterable_rest();
}
function _to_consumable_array(arr) {
    return _array_without_holes(arr) || _iterable_to_array(arr) || _unsupported_iterable_to_array(arr) || _non_iterable_spread();
}
function _type_of(obj) {
    "@swc/helpers - typeof";
    return obj && typeof Symbol !== "undefined" && obj.constructor === Symbol ? "symbol" : typeof obj;
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
import { isEmpty, isEqual, isObject, isPlainObject, sortBy, transform } from 'lodash';
/**
 * Deep diff between two object, using lodash.
 *
 * @param {Object} object                    Object compared
 * @param {Object} base                      Object to compare with
 * @param {function(*,*)} handleDiffCallback Callback to handle diffs.
 * @param {function(*,*)} equalsCallback     Callback to handle custom equals. Add your logic for comparing two values. Must return bool.
 *
 * @return {Object}                          Return a new object who represent the diff
 */ export var deepDiff = function deepDiff1(object, base, handleDiffCallback, equalsCallback) {
    if (typeof handleDiffCallback === 'undefined') {
        handleDiffCallback = function(baseValue, value) {
            return value;
        };
    }
    if (typeof equalsCallback === 'undefined') {
        equalsCallback = function(baseValue, value) {
            return isEqual(value, baseValue);
        };
    }
    return transform(object, function(result, value, key) {
        if (!equalsCallback(base[key], value)) {
            if (isObject(value) && isObject(base[key])) {
                var subObjectDiffResult = deepDiff(value, base[key], handleDiffCallback, equalsCallback);
                if (!isEmpty(subObjectDiffResult)) {
                    result[key] = subObjectDiffResult;
                }
            } else {
                result[key] = handleDiffCallback(base[key], value);
            }
        }
    });
};
export var objectToForm = function objectToForm(formObject, $form) {
    $form.find('input, select, textarea').each(function() {
        // Only elements that have a name attribute are interesting for us
        if (this.name) {
            var name = this.name;
            var input = $form.find("[name='".concat(name, "']"));
            if (input.attr('type') === 'checkbox' || input.attr('type') === 'radio') {
                input.attr('checked', formObject[name]);
            } else {
                input.val(formObject[name]);
            }
        }
    });
};
export var getObjectPropertiesIfDefined = function(fromObject) {
    return Object.fromEntries(Object.entries(fromObject).filter(function(param) {
        var _param = _sliced_to_array(param, 2), _ = _param[0], value = _param[1];
        return value !== null && value !== undefined && value !== '' && !(Array.isArray(value) && !value.length) && !(value.constructor === Object && Object.keys(value).length === 0);
    }));
};
export var stringifyInternalObjects = function(obj) {
    for(var prop in obj){
        if (_type_of(obj[prop]) === 'object' && obj[prop] !== null) {
            obj[prop] = JSON.stringify(obj[prop]);
        }
    }
    return obj;
};
/**
 * Shallow diff between two objects
 * https://dmitripavlutin.com/how-to-compare-objects-in-javascript/#3-shallow-equality
 *
 * @param {Object} obj1                      Object compared
 * @param {Object} obj2                      Object to compare with
 * @param {function(*,*)} handleDiffCallback Callback to handle diffs.
 * @param {function(*,*)} equalsCallback     Callback to handle custom equals. Add your logic for comparing two values. Must return bool.
 *
 * @return {Object}                          Return a new object who represent the diff
 */ export var shallowDiffUsingAllKeys = function shallowDiffUsingAllKeys(obj1, obj2) {
    var handleDiffCallback = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : undefined, equalsCallback = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : undefined;
    // Get an array of all keys from the union of both objects
    var keys = _to_consumable_array(new Set(_to_consumable_array(Object.keys(obj1)).concat(_to_consumable_array(Object.keys(obj2)))));
    var diff = {};
    if (typeof handleDiffCallback !== 'function') {
        handleDiffCallback = function(value1, value2) {
            return value2;
        };
    }
    if (typeof equalsCallback !== 'function') {
        equalsCallback = function(value1, value2) {
            var isValue1Empty = value1 === null || value1 === undefined || value1 === '' || Array.isArray(value1) && !value1.length;
            var isValue2Empty = value2 === null || value2 === undefined || value2 === '' || Array.isArray(value2) && !value2.length;
            if (isValue1Empty && isValue2Empty) {
                return true;
            }
            if (Array.isArray(value1) && Array.isArray(value2)) {
                return isEqual(sortBy(value1), sortBy(value2));
            }
            if (isPlainObject(value1) && isPlainObject(value2)) {
                return isEqual(value1, value2);
            }
            return value1 === value2;
        };
    }
    var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
    try {
        // Iterate over each key and compare values
        for(var _iterator = keys[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
            var key = _step.value;
            var value1 = obj1[key];
            var value2 = obj2[key];
            if (equalsCallback(value1, value2)) {
                continue;
            }
            diff[key] = handleDiffCallback(value1, value2);
        }
    } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
    } finally{
        try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
            }
        } finally{
            if (_didIteratorError) {
                throw _iteratorError;
            }
        }
    }
    return diff;
};
export function flattenObject(obj) {
    var parentKey = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : '', result = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {};
    for(var key in obj){
        if (Object.hasOwn(obj, key)) {
            var newKey = parentKey ? "".concat(parentKey, ".").concat(key) : key;
            if (_type_of(obj[key]) === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
                // Recursively flatten the nested object
                flattenObject(obj[key], newKey, result);
            } else {
                // Assign the value to the new key in the result object
                result[newKey] = obj[key];
            }
        }
    }
    return result;
}
