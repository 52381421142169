var routes = {
    absolute: false,
    rootUrl: 'http://[tenant].staffcloud.local',
    routes: [
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner",
            "name": "planner.index",
            "action": "StaffCloud\Applications\Planner\Http\Controllers\BaseController@getRedirectHome"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "employee",
            "name": "employee.index",
            "action": "StaffCloud\Applications\Employee\Http\Controllers\BaseController@getRedirectHome"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "logout",
            "name": "logout",
            "action": "StaffCloud\Applications\Authentication\Http\Controllers\AuthenticationController@getLogout"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "authentication\/two-factor\/resend",
            "name": "post-resend-two-factor-auth",
            "action": "StaffCloud\Applications\Authentication\Http\Controllers\TwoFactorAuthenticationController@postResend"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "js-translation",
            "name": "jsTranslation",
            "action": "StaffCloud\Shared\Http\Controllers\InternalController@postJsTranslation"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "employee\/initial-data",
            "name": "employee.initialData",
            "action": "StaffCloud\Applications\Employee\Http\Controllers\BaseController@getInitialData"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "employee\/feature-not-available",
            "name": "employee.featureNotAvailable",
            "action": "StaffCloud\Applications\Employee\Http\Controllers\BaseController@getFeatureNotAvailableMessage"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "employee\/message-composer\/suggest-receivers",
            "name": "employee.messageComposer.suggestReceivers",
            "action": "StaffCloud\Applications\Employee\Http\Controllers\MessageComposerController@postSuggestReceivers"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "employee\/message-composer\/send",
            "name": "employee.messageComposer.send",
            "action": "StaffCloud\Applications\Employee\Http\Controllers\MessageComposerController@postSend"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "employee\/dynamic-form\/get-form",
            "name": "employee.dynamicForm.getForm",
            "action": "StaffCloud\Applications\Employee\Http\Controllers\DynamicFormController@getForm"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "employee\/account",
            "name": "employee.account.account",
            "action": "StaffCloud\Applications\Employee\Modules\Account\Http\Controllers\MyAccountController@getAccount"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "employee\/account\/pictures\/list",
            "name": "employee.account.pictures.list",
            "action": "StaffCloud\Applications\Employee\Modules\Account\Http\Controllers\MyPicturesController@getList"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "employee\/account\/pictures\/delete\/{photoId}",
            "name": "employee.account.pictures.delete",
            "action": "StaffCloud\Applications\Employee\Modules\Account\Http\Controllers\MyPicturesController@getDeletePhoto"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "employee\/account\/settings",
            "name": "employee.account.settings",
            "action": "StaffCloud\Applications\Employee\Modules\Account\Http\Controllers\MySettingsController@getSettings"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "employee\/assignments\/event-invitations",
            "name": "employee.assignments.event-invitations",
            "action": "StaffCloud\Applications\Employee\Modules\Assignments\Http\Controllers\EventInvitationController@getIndex"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "employee\/assignments\/mine",
            "name": "employee.assignments.my",
            "action": "StaffCloud\Applications\Employee\Modules\Assignments\Http\Controllers\MyAssignmentsController@getIndex"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "employee\/assignments\/team-sheet\/{eventId}\/{projectId}",
            "name": "employee.assignments.teamSheet",
            "action": "StaffCloud\Applications\Employee\Modules\Assignments\Http\Controllers\MyAssignmentsController@getTeamSheet"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "employee\/assignments\/event-invitations\/event\/details\/{eventId}",
            "name": "employee.assignments.event-invitations.event.details",
            "action": "StaffCloud\Applications\Employee\Modules\Assignments\Http\Controllers\EventInvitationController@getEventDetailsPreview"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "employee\/assignments\/event-invitations\/project\/details\/{projectId}",
            "name": "employee.assignments.event-invitations.project.details",
            "action": "StaffCloud\Applications\Employee\Modules\Assignments\Http\Controllers\EventInvitationController@getProjectDetailsPreview"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "employee\/assignments\/event-invitations\/get",
            "name": "employee.assignments.event-invitations.get",
            "action": "StaffCloud\Applications\Employee\Modules\Assignments\Http\Controllers\EventInvitationController@postAssignments"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "employee\/assignments\/event-invitations\/get-details",
            "name": "employee.assignments.event-invitations.get.details",
            "action": "StaffCloud\Applications\Employee\Modules\Assignments\Http\Controllers\EventInvitationController@postAssignmentDetails"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "employee\/assignments\/event-invitations\/reporting-forms-data",
            "name": "employee.assignments.event-invitations.reporting-forms-data",
            "action": "StaffCloud\Applications\Employee\Modules\Assignments\Http\Controllers\EventInvitationController@getReportingFormsData"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "employee\/assignments\/reporting-forms-submissions\/{assignmentIds}",
            "name": "employee.assignments.reportingFormsSubmissions",
            "action": "StaffCloud\Applications\Employee\Modules\Assignments\Http\Controllers\EventInvitationController@getReportingFormsSubmissions"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "employee\/assignments\/reporting-form-redirect\/{formId}\/{assignmentId}",
            "name": "employee.assignments.reportingFormRedirect",
            "action": "StaffCloud\Applications\Employee\Modules\Assignments\Http\Controllers\EventInvitationController@getReportingFormRedirect"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "employee\/assignments\/my\/get",
            "name": "employee.assignments.my.get",
            "action": "StaffCloud\Applications\Employee\Modules\Assignments\Http\Controllers\MyAssignmentsController@postAssignments"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "employee\/assignments\/my\/paysheet\/{assignmentId}",
            "name": "employee.assignments.my.paysheet",
            "action": "StaffCloud\Applications\Employee\Modules\Assignments\Http\Controllers\MyAssignmentsController@getPaysheet"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "employee\/assignments\/change\/status",
            "name": "employee.assignments.my.change.status",
            "action": "StaffCloud\Applications\Employee\Modules\Assignments\Http\Controllers\MyAssignmentsController@postStateTransition"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "employee\/assignments\/{assignmentId}\/work-data-proposals",
            "name": "employee.assignments.work-data-proposals.save",
            "action": "StaffCloud\Applications\Employee\Modules\Assignments\Http\Controllers\MyAssignmentsController@saveWorkDataProposals"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "employee\/assignments\/{assignmentId}\/wage-proposal\/delete-file",
            "name": "employee.assignments.wage-proposal.delete-file",
            "action": "StaffCloud\Applications\Employee\Modules\Assignments\Http\Controllers\MyAssignmentsController@deleteWageProposalFile"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "employee\/assignments\/{assignmentId}\/wage-proposal\/{id}\/add-files",
            "name": "employee.assignments.wage-proposal.add-files",
            "action": "StaffCloud\Applications\Employee\Modules\Assignments\Http\Controllers\MyAssignmentsController@addWageProposalFiles"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "employee\/assignments\/{assignmentId}\/wage-proposal\/download\/{fileId}",
            "name": "employee.assignments.wage-proposal.download",
            "action": "StaffCloud\Applications\Employee\Modules\Assignments\Http\Controllers\MyAssignmentsController@downloadWageProposalFile"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "employee\/availabilities\/request\/get",
            "name": "employee.availabilities.request.get",
            "action": "StaffCloud\Applications\Employee\Modules\Availabilities\Http\Controllers\AvailabilitiesController@postGetRequests"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "employee\/availabilities\/request\/get-data",
            "name": "employee.availabilities.request.get-data",
            "action": "StaffCloud\Applications\Employee\Modules\Availabilities\Http\Controllers\AvailabilitiesController@postGetRequestData"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "employee\/availabilities\/request\/availabilities\/save",
            "name": "employee.availabilities.request.availabilities.save",
            "action": "StaffCloud\Applications\Employee\Modules\Availabilities\Http\Controllers\AvailabilitiesController@postSaveAvailabilities"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "employee\/availabilitycalendar\/data",
            "name": "employee.availabilitycalendar.getData",
            "action": "StaffCloud\Applications\Employee\Modules\AvailabilityCalendar\Http\Controllers\AvailabilityCalendarController@postCalendarData"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "employee\/availabilitycalendar\/busy\/period",
            "name": "employee.availabilitycalendar.busyPeriod.getPopup",
            "action": "StaffCloud\Applications\Employee\Modules\AvailabilityCalendar\Http\Controllers\AvailabilityCalendarController@getBusyPeriodPopup"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "employee\/availabilitycalendar\/busy\/period\/add",
            "name": "employee.availabilitycalendar.busyPeriod.add",
            "action": "StaffCloud\Applications\Employee\Modules\AvailabilityCalendar\Http\Controllers\AvailabilityCalendarController@postBusyPeriod"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "employee\/availabilitycalendar\/busy\/period\/remove",
            "name": "employee.availabilitycalendar.busyPeriod.remove",
            "action": "StaffCloud\Applications\Employee\Modules\AvailabilityCalendar\Http\Controllers\AvailabilityCalendarController@postRemoveBusyPeriod"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "employee\/availabilitycalendar\/busy\/period\/update",
            "name": "employee.availabilitycalendar.busyPeriod.getUpdate",
            "action": "StaffCloud\Applications\Employee\Modules\AvailabilityCalendar\Http\Controllers\AvailabilityCalendarController@getUpdateBusyPeriodPopup"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "employee\/availabilitycalendar\/busy\/period\/update",
            "name": "employee.availabilitycalendar.busyPeriod.postUpdate",
            "action": "StaffCloud\Applications\Employee\Modules\AvailabilityCalendar\Http\Controllers\AvailabilityCalendarController@postUpdateBusyPeriod"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "employee\/inbox\/{inboxFolder}",
            "name": "employee.inbox.inbox",
            "action": "StaffCloud\Applications\Employee\Modules\Inbox\Http\Controllers\InboxController@getIndex"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "employee\/inbox\/messages\/{folder}",
            "name": "employee.inbox.inbox.messages",
            "action": "StaffCloud\Applications\Employee\Modules\Inbox\Http\Controllers\InboxController@getMessages"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "employee\/inbox\/conversation\/{conversationIdentifier}",
            "name": "employee.inbox.inbox.conversation",
            "action": "StaffCloud\Applications\Employee\Modules\Inbox\Http\Controllers\InboxController@getMessageThread"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "employee\/inbox\/conversation\/delete\/{conversationIdentifier}",
            "name": "employee.inbox.inbox.conversation.delete",
            "action": "StaffCloud\Applications\Employee\Modules\Inbox\Http\Controllers\InboxController@deleteMessageThread"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "employee\/inbox\/inbox\/mark-all-as-read\/{type}",
            "name": "employee.inbox.inbox.markAllAsRead",
            "action": "StaffCloud\Applications\Employee\Modules\Inbox\Http\Controllers\InboxController@markAllAsRead"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/initial-data",
            "name": "planner.initialData",
            "action": "StaffCloud\Applications\Planner\Http\Controllers\BaseController@getInitialData"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/expiring-fields",
            "name": "planner.expiring-fields.index",
            "action": "StaffCloud\Applications\Planner\Http\Controllers\ExpiringFieldsController@getIndex"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/expiring-fields\/list",
            "name": "planner.expiring-fields.list",
            "action": "StaffCloud\Applications\Planner\Http\Controllers\ExpiringFieldsController@getListExpiringFields"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/expiring-fields\/assignments-after-expire-counts",
            "name": "planner.expiring-fields.assignments-after-expire-counts",
            "action": "StaffCloud\Applications\Planner\Http\Controllers\ExpiringFieldsController@postCountAssignmentsAfterExpire"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/volume-exceeded",
            "name": "planner.volumeExceeded",
            "action": "StaffCloud\Applications\Planner\Http\Controllers\BaseController@getVolumeExceededMessage"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/feature-not-available",
            "name": "planner.featureNotAvailable",
            "action": "StaffCloud\Applications\Planner\Http\Controllers\BaseController@getFeatureNotAvailableMessage"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/file-versioning\/list",
            "name": "planner.file-versioning.list",
            "action": "StaffCloud\Applications\Planner\Http\Controllers\FilesVersioningController@postListEntitiesFiles"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/file-versioning\/delete",
            "name": "planner.file-versioning.delete",
            "action": "StaffCloud\Applications\Planner\Http\Controllers\FilesVersioningController@postDeleteEntityFile"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/message-composer\/suggest_receivers",
            "name": "planner.messageComposer.suggestReceivers",
            "action": "StaffCloud\Applications\Planner\Http\Controllers\MessageComposerController@postSuggestReceivers"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/message-composer\/send",
            "name": "planner.messageComposer.send",
            "action": "StaffCloud\Applications\Planner\Http\Controllers\MessageComposerController@postSend"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/message-composer\/plugins",
            "name": "planner.messageComposer.plugins",
            "action": "StaffCloud\Applications\Planner\Http\Controllers\MessageComposerController@postGetPlugins"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/rate-employee\/{employeeId}",
            "name": "planner.getRateEmployee",
            "action": "StaffCloud\Applications\Planner\Http\Controllers\RatingsController@getRateEmployeePopup"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/rate-employee",
            "name": "planner.postRateEmployee",
            "action": "StaffCloud\Applications\Planner\Http\Controllers\RatingsController@postRateEmployee"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/delete-rating",
            "name": "planner.postDeleteRating",
            "action": "StaffCloud\Applications\Planner\Http\Controllers\RatingsController@postDeleteRating"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/profiles\/image-configurator\/images-configuration\/{employeePicturesIds}",
            "name": "planner.profiles.image-configurator.get-images-configuration",
            "action": "StaffCloud\Applications\Planner\Http\Controllers\ProfilesController@getImagesConfiguration"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/profiles\/image-configurator\/save-images-configuration",
            "name": "planner.profiles.image-configurator.save-images-configuration",
            "action": "StaffCloud\Applications\Planner\Http\Controllers\ProfilesController@postImagesConfiguration"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/profiles\/image-configurator\/add-tag",
            "name": "planner.profiles.image-configurator.add-tag",
            "action": "StaffCloud\Applications\Planner\Http\Controllers\ProfilesController@postAddTag"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/profiles\/initializer\/initialize",
            "name": "planner.profiles.initializer.initialize",
            "action": "StaffCloud\Applications\Planner\Http\Controllers\ProfilesController@initialize"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/profiles\/profile-configurator\/save-preset",
            "name": "planner.profiles.profile-configurator.save-preset",
            "action": "StaffCloud\Applications\Planner\Http\Controllers\ProfilesController@postSavePreset"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/profiles\/profile-configurator\/delete-preset",
            "name": "planner.profiles.profile-configurator.delete-preset",
            "action": "StaffCloud\Applications\Planner\Http\Controllers\ProfilesController@postDeletePreset"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/profiles\/profile-configurator\/save-profile",
            "name": "planner.profiles.profile-configurator.save-profile",
            "action": "StaffCloud\Applications\Planner\Http\Controllers\ProfilesController@postSaveProfile"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/profiles\/profile-configurator\/custom-logo-form",
            "name": "planner.profiles.profile-configurator.custom-logo-form",
            "action": "StaffCloud\Applications\Planner\Http\Controllers\ProfilesController@getCustomLogoForm"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/profiles\/profile-configurator\/custom-logo-form",
            "name": "planner.profiles.profile-configurator.upload-custom-logo",
            "action": "StaffCloud\Applications\Planner\Http\Controllers\ProfilesController@postUploadCustomLogo"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/profiles\/profile-configurator\/custom-logo-delete",
            "name": "planner.profiles.profile-configurator.custom-logo-delete",
            "action": "StaffCloud\Applications\Planner\Http\Controllers\ProfilesController@postDeleteCustomLogo"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/profiles\/profile-previewer\/load-profile",
            "name": "planner.profiles.profile-previewer.load-profile",
            "action": "StaffCloud\Applications\Planner\Http\Controllers\ProfilesController@postLoadProfile"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/profiles\/download\/{identifier}\/{archived}\/{format?}",
            "name": "planner.profiles.download-profile-by-identifier",
            "action": "StaffCloud\Applications\Planner\Http\Controllers\ProfilesController@getDownloadProfileByIdentifier"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/profiles\/delete-profile",
            "name": "planner.profiles.delete-profile",
            "action": "StaffCloud\Applications\Planner\Http\Controllers\ProfilesController@postDeleteProfile"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/pricing-plan\/volumes\/remaining-volume-units",
            "name": "planner.pricingPlan.volumes.remainingVolumeUnits",
            "action": "StaffCloud\Applications\Planner\Http\Controllers\PricingPlanController@getRemainingVolumeUnits"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/pricing-plan\/volumes\/is-empty",
            "name": "planner.pricingPlan.volumes.isVolumeEmpty",
            "action": "StaffCloud\Applications\Planner\Http\Controllers\PricingPlanController@isVolumeEmpty"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/pricing-plan\/volumes\/use-volume-units",
            "name": "planner.pricingPlan.volumes.useVolumeUnits",
            "action": "StaffCloud\Applications\Planner\Http\Controllers\PricingPlanController@useVolumeUnits"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/pricing-plan\/volumes\/get",
            "name": "planner.pricingPlan.volumes.getVolume",
            "action": "StaffCloud\Applications\Planner\Http\Controllers\PricingPlanController@getVolume"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/employees\/profile-pictures\/get-urls",
            "name": "planner.employees.profile-pictures.getUrls",
            "action": "StaffCloud\Applications\Planner\Http\Controllers\EmployeesController@postGetEmployeesProfilePicturesUrls"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/download\/{filename}",
            "name": "planner.download",
            "action": "StaffCloud\Applications\Planner\Http\Controllers\DownloadController@download"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/autocomplete-search\/dynamic\/{entityName}\/{attributeId}",
            "name": "planner.autocompleteSearch.dynamic",
            "action": "StaffCloud\Applications\Planner\Http\Controllers\AutocompleteSearchController@getDynamic"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/autocomplete-search\/full-name\/{entityName}",
            "name": "planner.autocompleteSearch.fullName",
            "action": "StaffCloud\Applications\Planner\Http\Controllers\AutocompleteSearchController@getFullName"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/autocomplete-search\/project-name",
            "name": "planner.autocompleteSearch.projectName",
            "action": "StaffCloud\Applications\Planner\Http\Controllers\AutocompleteSearchController@getProjectName"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/mail\/registered-email-checker\/refresh-domains",
            "name": "planner.mail.registeredEmailChecker.refreshDomains",
            "action": "StaffCloud\Applications\Planner\Http\Controllers\MailController@postRefreshDomains"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/mail\/registered-email-checker\/check-email",
            "name": "planner.mail.registeredEmailChecker.checkEmail",
            "action": "StaffCloud\Applications\Planner\Http\Controllers\MailController@postCheckEmail"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/information\/get-data",
            "name": "planner.information.getData",
            "action": "StaffCloud\Applications\Planner\Http\Controllers\InformationController@getData"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/dynamic-form\/get-form",
            "name": "planner.dynamicForm.getForm",
            "action": "StaffCloud\Applications\Planner\Http\Controllers\DynamicFormController@getForm"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/dynamic-form\/get-attribute",
            "name": "planner.dynamicForm.getAttribute",
            "action": "StaffCloud\Applications\Planner\Http\Controllers\DynamicFormController@getAttribute"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/account",
            "name": "planner.account.manage",
            "action": "StaffCloud\Applications\Planner\Modules\Account\Http\Controllers\AccountController@getManage"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/account\/inbox\/{folder}",
            "name": "planner.account.inbox",
            "action": "StaffCloud\Applications\Planner\Modules\Account\Http\Controllers\InboxController@getInbox"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/account\/inbox\/messages\/{folder}",
            "name": "planner.account.inbox.messages",
            "action": "StaffCloud\Applications\Planner\Modules\Account\Http\Controllers\InboxController@getMessages"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/account\/inbox\/conversation\/{conversationIdentifier}",
            "name": "planner.account.inbox.conversation",
            "action": "StaffCloud\Applications\Planner\Modules\Account\Http\Controllers\InboxController@getMessageThread"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/account\/inbox\/conversation\/delete\/{conversationIdentifier}",
            "name": "planner.account.inbox.conversation.delete",
            "action": "StaffCloud\Applications\Planner\Modules\Account\Http\Controllers\InboxController@deleteMessageThread"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/account\/inbox\/mark-all-as-read\/{type}",
            "name": "planner.account.inbox.markAllAsRead",
            "action": "StaffCloud\Applications\Planner\Modules\Account\Http\Controllers\InboxController@markAllAsRead"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/account\/inbox\/signature",
            "name": "planner.account.inbox.signature",
            "action": "StaffCloud\Applications\Planner\Modules\Account\Http\Controllers\InboxController@getSignature"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/activitylog\/index",
            "name": "planner.activitylog.index",
            "action": "StaffCloud\Applications\Planner\Modules\ActivityLog\Http\Controllers\ActivityLogController@postGetActivityLog"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/activitylog\/data\/changes\/{activityEntityId}",
            "name": "planner.activitylog.data.change.table",
            "action": "StaffCloud\Applications\Planner\Modules\ActivityLog\Http\Controllers\ActivityLogController@getDataChangeTable"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/activitylog\/event\/functions\/list",
            "name": "planner.activitylog.event.function.list",
            "action": "StaffCloud\Applications\Planner\Modules\ActivityLog\Http\Controllers\ActivityLogController@postEventFunctionsList"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/activitylog\/action\/resolver",
            "name": "planner.activitylog.action.resolver",
            "action": "StaffCloud\Applications\Planner\Modules\ActivityLog\Http\Controllers\ActivityLogController@postActionResolver"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/activitylog\/popup",
            "name": "planner.activitylog.popup",
            "action": "StaffCloud\Applications\Planner\Modules\ActivityLog\Http\Controllers\ActivityLogController@getPopup"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/administration\/planners\/load-license-manager-data\/{licenseId}",
            "name": "planner.administration.planners.loadLicenseManagerData",
            "action": "StaffCloud\Applications\Planner\Modules\Administration\Http\Controllers\PlannersController@getLoadLicenseManagerData"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/administration\/planners\/unused-licenses\/{plannerId?}",
            "name": "planner.administration.planners.unusedLicenses",
            "action": "StaffCloud\Applications\Planner\Modules\Administration\Http\Controllers\ACLController@getUnusedLicenses"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/administration\/planners\/save",
            "name": "planner.administration.planners.save",
            "action": "StaffCloud\Applications\Planner\Modules\Administration\Http\Controllers\PlannersController@postSave"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/administration\/planners\/lock",
            "name": "planner.administration.planners.lock",
            "action": "StaffCloud\Applications\Planner\Modules\Administration\Http\Controllers\PlannersController@postLock"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/administration\/planners\/unlock",
            "name": "planner.administration.planners.unlock",
            "action": "StaffCloud\Applications\Planner\Modules\Administration\Http\Controllers\PlannersController@postUnlock"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/administration\/planners\/send-password-reset-email",
            "name": "planner.administration.planners.sendPasswordResetEmail",
            "action": "StaffCloud\Applications\Planner\Modules\Administration\Http\Controllers\PlannersController@postSendPasswordResetEmail"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/administration\/planners\/revoke-license",
            "name": "planner.administration.planners.revokeLicense",
            "action": "StaffCloud\Applications\Planner\Modules\Administration\Http\Controllers\PlannersController@postRevokeLicense"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/administration\/planners\/delete",
            "name": "planner.administration.planners.delete",
            "action": "StaffCloud\Applications\Planner\Modules\Administration\Http\Controllers\PlannersController@postDelete"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/administration\/planners\/restore",
            "name": "planner.administration.planners.restore",
            "action": "StaffCloud\Applications\Planner\Modules\Administration\Http\Controllers\PlannersController@postRestore"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/administration\/planners\/save-license",
            "name": "planner.administration.planners.saveLicense",
            "action": "StaffCloud\Applications\Planner\Modules\Administration\Http\Controllers\PlannersController@postSaveLicense"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/administration\/planners\/acl\/delete-role",
            "name": "planner.administration.planners.acl.deleteRole",
            "action": "StaffCloud\Applications\Planner\Modules\Administration\Http\Controllers\ACLController@postDeleteRole"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/administration\/planners\/acl\/save-role",
            "name": "planner.administration.planners.acl.saveRole",
            "action": "StaffCloud\Applications\Planner\Modules\Administration\Http\Controllers\ACLController@postSaveRole"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/availabilities\/time-slot\/time-slot-group\/get",
            "name": "planner.availabilities.time-slot.time-slot-group.get",
            "action": "StaffCloud\Applications\Planner\Modules\Availabilities\Http\Controllers\TimeSlotController@postGetTimeSlotsGroups"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/availabilities\/time-slot\/get",
            "name": "planner.availabilities.time-slot.get",
            "action": "StaffCloud\Applications\Planner\Modules\Availabilities\Http\Controllers\TimeSlotController@postGet"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/availabilities\/time-slot\/save",
            "name": "planner.availabilities.time-slot.save",
            "action": "StaffCloud\Applications\Planner\Modules\Availabilities\Http\Controllers\TimeSlotController@postSave"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/availabilities\/time-slot\/archive",
            "name": "planner.availabilities.time-slot.archive",
            "action": "StaffCloud\Applications\Planner\Modules\Availabilities\Http\Controllers\TimeSlotController@postArchive"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/availabilities\/time-slot\/reactivate",
            "name": "planner.availabilities.time-slot.reactivate",
            "action": "StaffCloud\Applications\Planner\Modules\Availabilities\Http\Controllers\TimeSlotController@postReactivate"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/availabilities\/time-slot-category\/get",
            "name": "planner.availabilities.time-slot-category.get",
            "action": "StaffCloud\Applications\Planner\Modules\Availabilities\Http\Controllers\TimeSlotCategoryController@postGet"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/availabilities\/time-slot-category\/save",
            "name": "planner.availabilities.time-slot-category.save",
            "action": "StaffCloud\Applications\Planner\Modules\Availabilities\Http\Controllers\TimeSlotCategoryController@postSave"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/availabilities\/time-slot-category\/archive",
            "name": "planner.availabilities.time-slot-category.archive",
            "action": "StaffCloud\Applications\Planner\Modules\Availabilities\Http\Controllers\TimeSlotCategoryController@postArchive"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/availabilities\/time-slot-category\/reactivate",
            "name": "planner.availabilities.time-slot-category.reactivate",
            "action": "StaffCloud\Applications\Planner\Modules\Availabilities\Http\Controllers\TimeSlotCategoryController@postReactivate"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/availabilities\/time-slot-group\/time-slot\/get",
            "name": "planner.availabilities.time-slot-group.time-slot.get",
            "action": "StaffCloud\Applications\Planner\Modules\Availabilities\Http\Controllers\TimeSlotGroupController@postGetTimeSlots"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/availabilities\/time-slot-group\/get",
            "name": "planner.availabilities.time-slot-group.get",
            "action": "StaffCloud\Applications\Planner\Modules\Availabilities\Http\Controllers\TimeSlotGroupController@postGet"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/availabilities\/time-slot-group\/save",
            "name": "planner.availabilities.time-slot-group.save",
            "action": "StaffCloud\Applications\Planner\Modules\Availabilities\Http\Controllers\TimeSlotGroupController@postSave"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/availabilities\/time-slot-group\/archive",
            "name": "planner.availabilities.time-slot-group.archive",
            "action": "StaffCloud\Applications\Planner\Modules\Availabilities\Http\Controllers\TimeSlotGroupController@postArchive"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/availabilities\/time-slot-group\/reactivate",
            "name": "planner.availabilities.time-slot-group.reactivate",
            "action": "StaffCloud\Applications\Planner\Modules\Availabilities\Http\Controllers\TimeSlotGroupController@postReactivate"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/availabilities\/request\/employees\/search-form",
            "name": "planner.availabilities.request.employees.search-form",
            "action": "StaffCloud\Applications\Planner\Modules\Availabilities\Http\Controllers\RequestController@getEmployeesSearchForm"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/availabilities\/request\/employees\/search",
            "name": "planner.availabilities.request.employees.search",
            "action": "StaffCloud\Applications\Planner\Modules\Availabilities\Http\Controllers\RequestController@postSearchEmployees"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/availabilities\/request\/employees\/save",
            "name": "planner.availabilities.request.employees.save",
            "action": "StaffCloud\Applications\Planner\Modules\Availabilities\Http\Controllers\RequestController@postSaveRequestEmployees"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/availabilities\/request\/employees\/delete",
            "name": "planner.availabilities.request.employees.delete",
            "action": "StaffCloud\Applications\Planner\Modules\Availabilities\Http\Controllers\RequestController@postDeleteEmployeesRequests"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/availabilities\/request\/employees\/block",
            "name": "planner.availabilities.request.employees.block",
            "action": "StaffCloud\Applications\Planner\Modules\Availabilities\Http\Controllers\RequestController@postBlockEmployeesRequests"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/availabilities\/request\/employees\/unblock",
            "name": "planner.availabilities.request.employees.unblock",
            "action": "StaffCloud\Applications\Planner\Modules\Availabilities\Http\Controllers\RequestController@postUnblockEmployeesRequests"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/availabilities\/request\/employees\/get",
            "name": "planner.availabilities.request.employees.get",
            "action": "StaffCloud\Applications\Planner\Modules\Availabilities\Http\Controllers\RequestController@postGetRequestsEmployees"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/availabilities\/request\/enforcements\/get",
            "name": "planner.availabilities.request.enforcements.get",
            "action": "StaffCloud\Applications\Planner\Modules\Availabilities\Http\Controllers\RequestController@postGetEnforcements"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/availabilities\/request\/with-relations\/get",
            "name": "planner.availabilities.request.with-relations.get",
            "action": "StaffCloud\Applications\Planner\Modules\Availabilities\Http\Controllers\RequestController@postGetWithRelations"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/availabilities\/request\/get",
            "name": "planner.availabilities.request.get",
            "action": "StaffCloud\Applications\Planner\Modules\Availabilities\Http\Controllers\RequestController@postGet"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/availabilities\/request\/save",
            "name": "planner.availabilities.request.save",
            "action": "StaffCloud\Applications\Planner\Modules\Availabilities\Http\Controllers\RequestController@postSave"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/availabilities\/request\/delete",
            "name": "planner.availabilities.request.delete",
            "action": "StaffCloud\Applications\Planner\Modules\Availabilities\Http\Controllers\RequestController@postDelete"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/availabilities\/policy\/get",
            "name": "planner.availabilities.policy.get",
            "action": "StaffCloud\Applications\Planner\Modules\Availabilities\Http\Controllers\PolicyController@postGet"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/availabilities\/policy\/archive",
            "name": "planner.availabilities.policy.archive",
            "action": "StaffCloud\Applications\Planner\Modules\Availabilities\Http\Controllers\PolicyController@postArchive"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/availabilities\/filters\/get-attributes-definition",
            "name": "planner.availabilities.filters.get-attributes-definition",
            "action": "StaffCloud\Applications\Planner\Modules\Availabilities\Http\Controllers\FiltersController@postGetAttributesDefinition"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/availabilities\/overview\/get-employees",
            "name": "planner.availabilities.overview.get-employees",
            "action": "StaffCloud\Applications\Planner\Modules\Availabilities\Http\Controllers\OverviewController@postGetEmployees"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/availabilities\/overview\/get-employees-dynamic-attributes",
            "name": "planner.availabilities.overview.get-employees-dynamic-attributes",
            "action": "StaffCloud\Applications\Planner\Modules\Availabilities\Http\Controllers\OverviewController@postGetEmployeesDynamicAttributes"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/availabilities\/overview\/get-availabilities",
            "name": "planner.availabilities.overview.get-availabilities",
            "action": "StaffCloud\Applications\Planner\Modules\Availabilities\Http\Controllers\OverviewController@postGetAvailabilities"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/availabilities\/overview\/get-busy-periods",
            "name": "planner.availabilities.overview.get-busy-periods",
            "action": "StaffCloud\Applications\Planner\Modules\Availabilities\Http\Controllers\OverviewController@postGetBusyPeriods"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/availabilities\/overview\/get-assignments",
            "name": "planner.availabilities.overview.get-assignments",
            "action": "StaffCloud\Applications\Planner\Modules\Availabilities\Http\Controllers\OverviewController@postGetAssignments"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/availabilities\/overview\/get-work-quotas",
            "name": "planner.availabilities.overview.get-work-quotas",
            "action": "StaffCloud\Applications\Planner\Modules\Availabilities\Http\Controllers\OverviewController@postGetWorkQuotas"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/availabilities\/time-slot\/delete",
            "name": "planner.availabilities.time-slot.delete",
            "action": "StaffCloud\Applications\Planner\Modules\Availabilities\Http\Controllers\TimeSlotController@postDelete"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/availabilities\/time-slot-category\/delete",
            "name": "planner.availabilities.time-slot-category.delete",
            "action": "StaffCloud\Applications\Planner\Modules\Availabilities\Http\Controllers\TimeSlotCategoryController@postDelete"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/availabilities\/time-slot-group\/delete",
            "name": "planner.availabilities.time-slot-group.delete",
            "action": "StaffCloud\Applications\Planner\Modules\Availabilities\Http\Controllers\TimeSlotGroupController@postDelete"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/availabilitycalendar\/data",
            "name": "planner.availabilitycalendar.getData",
            "action": "StaffCloud\Applications\Planner\Modules\AvailabilityCalendar\Http\Controllers\AvailabilityCalendarController@postCalendarData"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/availabilitycalendar\/busy\/period",
            "name": "planner.availabilitycalendar.busyPeriod.getPopup",
            "action": "StaffCloud\Applications\Planner\Modules\AvailabilityCalendar\Http\Controllers\AvailabilityCalendarController@getBusyPeriodPopup"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/availabilitycalendar\/busy\/period\/add",
            "name": "planner.availabilitycalendar.busyPeriod.add",
            "action": "StaffCloud\Applications\Planner\Modules\AvailabilityCalendar\Http\Controllers\AvailabilityCalendarController@postBusyPeriod"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/availabilitycalendar\/busy\/period\/remove",
            "name": "planner.availabilitycalendar.busyPeriod.remove",
            "action": "StaffCloud\Applications\Planner\Modules\AvailabilityCalendar\Http\Controllers\AvailabilityCalendarController@postRemoveBusyPeriod"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/availabilitycalendar\/busy\/period\/edit",
            "name": "planner.availabilitycalendar.busyPeriod.getUpdate",
            "action": "StaffCloud\Applications\Planner\Modules\AvailabilityCalendar\Http\Controllers\AvailabilityCalendarController@getUpdateBusyPeriodPopup"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/availabilitycalendar\/busy\/period\/update",
            "name": "planner.availabilitycalendar.busyPeriod.postUpdate",
            "action": "StaffCloud\Applications\Planner\Modules\AvailabilityCalendar\Http\Controllers\AvailabilityCalendarController@postUpdateBusyPeriod"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/codemanagement\/code-management\/download",
            "name": "planner.codemanagement.download",
            "action": "StaffCloud\Applications\Planner\Modules\CodeManagement\Http\Controllers\CodeManagementController@downloadAsPdf"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/settings\/appearance",
            "name": "planner.configuration.settings.appearance",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\SettingsController@getAppearance"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/settings\/internationalization",
            "name": "planner.configuration.settings.internationalization",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\SettingsController@getInternationalization"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/settings\/security",
            "name": "planner.configuration.settings.security",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\SettingsController@getSecurity"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/settings\/communication",
            "name": "planner.configuration.settings.communication",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\SettingsController@getCommunication"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/projects\/projectSettings",
            "name": "planner.configuration.projects.projectSettings",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\ProjectsController@getProjectSettings"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/projects\/eventSettings",
            "name": "planner.configuration.projects.eventSettings",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\ProjectsController@getEventSettings"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/projects\/staffPlanning",
            "name": "planner.configuration.projects.staffPlanning",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\ProjectsController@getStaffPlanning"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/recruiting\/generalSettings",
            "name": "planner.configuration.recruiting.generalSettings",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\RecruitingController@getGeneralSettings"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/employees\/generalSettings",
            "name": "planner.configuration.employees.generalSettings",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\EmployeesController@getGeneralSettings"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/employees\/ratings",
            "name": "planner.configuration.employees.ratings",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\EmployeesController@getRatings"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/addOns\/reporting",
            "name": "planner.configuration.addOns.reporting",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\AddOnsController@getReporting"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/addOns\/workTimeProposals",
            "name": "planner.configuration.addOns.workTimeProposals",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\AddOnsController@getWorkTimeProposals"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/addOns\/liveProposals",
            "name": "planner.configuration.addOns.liveProposals",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\AddOnsController@getLiveProposals"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/addOns\/workQuotaThresholds",
            "name": "planner.configuration.addOns.workQuotaThresholds",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\AddOnsController@getWorkQuotaThresholds"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/addOns\/contractsTemplates",
            "name": "planner.configuration.addOns.contractsTemplates",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\ContractTemplateController@getIndex"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/addOns\/contractsTemplates\/new",
            "name": "planner.configuration.addOns.contractsTemplates.new",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\ContractTemplateController@getIndex"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/addOns\/contractsTemplates\/edit\/{id}",
            "name": "planner.configuration.addOns.contractsTemplates.edit",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\ContractTemplateController@getIndex"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/addOns\/contractsTemplates\/list",
            "name": "planner.configuration.addOns.contractsTemplates.list",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\ContractTemplateController@getList"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/configuration\/addOns\/contractsTemplates\/createUpdateDependencies",
            "name": "planner.configuration.addOns.contractsTemplates.createUpdateDependencies",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\ContractTemplateController@getCreateUpdateDependencies"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/configuration\/addOns\/contractsTemplates\/getContractTemplate",
            "name": "planner.configuration.addOns.contractsTemplates.getContractTemplate",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\ContractTemplateController@getContractTemplate"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/configuration\/addOns\/contractsTemplates\/saveContractTemplate",
            "name": "planner.configuration.addOns.contractsTemplates.saveContractTemplate",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\ContractTemplateController@saveContractTemplate"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/configuration\/addOns\/contractsTemplates\/generatePdfData",
            "name": "planner.configuration.addOns.contractsTemplates.generatePdfData",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\ContractTemplateController@generatePdfData"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/forms\/{entity}",
            "name": "planner.configuration.forms.manage",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\FormsController@getManage"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/forms\/{entity}\/data\/{form}",
            "name": "planner.configuration.forms.data",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\FormsController@getData"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/forms\/{entity}\/preview\/{form}",
            "name": "planner.configuration.forms.preview",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\FormsController@getPreview"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/forms\/{entity}\/attribute\/new",
            "name": "planner.configuration.forms.newAttribute",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\FormsController@getNewAttribute"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/forms\/{entity}\/attribute\/edit\/{attribute}",
            "name": "planner.configuration.forms.editAttribute",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\FormsController@getEditAttribute"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/configuration\/forms\/attribute\/checkDuplication",
            "name": "planner.configuration.forms.checkDuplication",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\FormsController@checkDuplication"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/configuration\/forms\/{entity}\/attribute\/remove",
            "name": "planner.configuration.forms.removeFormAttribute",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\FormsController@postRemoveAttribute"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/configuration\/forms\/{entity}\/attribute\/delete\/{attribute}",
            "name": "planner.configuration.forms.deleteAttribute",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\FormsController@postDeleteAttribute"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/forms\/condition\/{conditionId}\/values",
            "name": "planner.configuration.forms.getConditionValuesFormElement",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\FormsController@getConditionValuesFormElement"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/forms\/collection\/{collectionId}\/values",
            "name": "planner.configuration.forms.getCollectionValues",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\FormsController@getCollectionValues"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/forms\/{entity}\/section\/new",
            "name": "planner.configuration.forms.newSection",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\FormsController@getNewSection"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/forms\/{entity}\/section\/clone",
            "name": "planner.configuration.forms.getCloneSection",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\FormsController@getCloneSection"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/forms\/{entity}\/section\/edit\/{section}",
            "name": "planner.configuration.forms.editSection",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\FormsController@getEditSection"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/configuration\/forms\/{entity}\/section\/position",
            "name": "planner.configuration.forms.positionSection",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\FormsController@postPositionSection"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/configuration\/forms\/{entity}\/section\/delete\/{section}",
            "name": "planner.configuration.forms.deleteSection",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\FormsController@postDeleteSection"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/configuration\/forms\/{entity}\/section\/position-attributes",
            "name": "planner.configuration.forms.updateSection.attributePositions",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\FormsController@postUpdateAttributePositions"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/settings\/me",
            "name": "planner.configuration.settings.me",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\SettingsController@getPlannerSettings"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/settings\/signatures",
            "name": "planner.configuration.settings.signatures",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\SettingsController@getSignatures"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/settings\/collections",
            "name": "planner.configuration.settings.collections",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\SettingsController@getCollections"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/collections\/manage",
            "name": "planner.configuration.collections.list",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\CollectionsController@list"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/collections\/add-collection",
            "name": "planner.configuration.collections.addCollection",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\CollectionsController@addCollection"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/configuration\/collections\/store-collection",
            "name": "planner.configuration.collections.storeCollection",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\CollectionsController@storeCollection"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/configuration\/collections\/delete-collection",
            "name": "planner.configuration.collections.deleteCollection",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\CollectionsController@deleteCollection"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/collections\/{id}\/list-items",
            "name": "planner.configuration.collections.listItems",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\CollectionsController@listItems"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/collections\/{collectionId}\/add-item",
            "name": "planner.configuration.collections.addItem",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\CollectionsController@addItem"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/configuration\/collections\/store-item",
            "name": "planner.configuration.collections.storeItem",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\CollectionsController@storeItem"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/configuration\/collections\/store-item-get-value",
            "name": "planner.configuration.collections.store-item-get-value",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\CollectionsController@storeItemGetValue"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/collections\/{collectionId}\/edit-item\/{id}",
            "name": "planner.configuration.collections.editItem",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\CollectionsController@editItem"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/configuration\/collections\/update-item",
            "name": "planner.configuration.collections.updateItem",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\CollectionsController@updateItem"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/configuration\/collections\/delete-item",
            "name": "planner.configuration.collections.deleteItem",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\CollectionsController@deleteItem"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/configuration\/collections\/sort-items",
            "name": "planner.configuration.collections.sortItems",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\CollectionsController@sortItems"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/configuration\/collections\/values",
            "name": "planner.configuration.collections.values",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\CollectionsController@getValues"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/notifications\/get\/{notificationIdentifier}\/language\/{languageId}",
            "name": "planner.configuration.notification.get",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\NotificationsController@getNotification"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/configuration\/notifications\/save\/{notificationIdentifier}\/language\/{languageId}",
            "name": "planner.configuration.notification.save",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\NotificationsController@postSaveNotification"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/notifications\/restore\/{notificationIdentifier}\/language\/{languageId}",
            "name": "planner.configuration.notification.restore.default",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\NotificationsController@getRestoreDefault"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/integration\/erp",
            "name": "planner.configuration.integration.erp",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\IntegrationController@getErpSettings"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/wages\/profiles",
            "name": "planner.configuration.wages.profiles",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\WagesController@getWageProfileSettings"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/wages\/types",
            "name": "planner.configuration.wages.types",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\WagesController@getWageTypeSettings"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/configuration\/wages\/types\/delete\/{wageTypeId}",
            "name": "planner.configuration.wages.types.deleteWageType",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\WagesController@postDeleteWageType"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/wages\/types\/load\/{wageTypeId}",
            "name": "planner.configuration.wages.types.loadWageType",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\WagesController@getWageType"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/configuration\/wages\/types\/save",
            "name": "planner.configuration.wages.types.saveWageType",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\WagesController@postWageType"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/configuration\/wages\/profiles\/delete\/{wageProfileId}",
            "name": "planner.configuration.wages.profiles.deleteWageProfile",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\WagesController@postDeleteWageProfile"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/configuration\/wages\/profiles\/updateStatus\/{wageProfileId}",
            "name": "planner.configuration.wages.profiles.postUpdateStatus",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\WagesController@postUpdateStatus"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/wages\/profiles\/load\/{wageProfileId}",
            "name": "planner.configuration.wages.profiles.loadWageProfile",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\WagesController@getWageProfile"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/configuration\/wages\/profiles\/save",
            "name": "planner.configuration.wages.profiles.saveWageProfile",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\WagesController@postWageProfile"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/work-quotas\/profiles",
            "name": "planner.configuration.work-quotas.profiles",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\WorkQuotaController@getWorkQuotaProfiles"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/work-quotas\/targets",
            "name": "planner.configuration.work-quotas.targets",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\WorkQuotaController@getWorkQuotaTargets"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/work-quotas\/agendas",
            "name": "planner.configuration.work-quotas.agendas",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\WorkQuotaController@getWorkQuotaAgendas"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/work-quotas\/targets\/load\/{targetId}",
            "name": "planner.configuration.work-quotas.targets.load",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\WorkQuotaController@getTarget"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/configuration\/work-quotas\/targets\/save",
            "name": "planner.configuration.work-quotas.targets.save",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\WorkQuotaController@postTarget"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/configuration\/work-quotas\/targets\/delete\/{targetId}",
            "name": "planner.configuration.work-quotas.targets.delete",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\WorkQuotaController@postDeleteTarget"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/work-quotas\/profiles\/load\/{profileId}",
            "name": "planner.configuration.work-quotas.profiles.load",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\WorkQuotaController@getProfile"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/configuration\/work-quotas\/profiles\/save",
            "name": "planner.configuration.work-quotas.profiles.save",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\WorkQuotaController@postProfile"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/configuration\/work-quotas\/profiles\/delete\/{profileId}",
            "name": "planner.configuration.work-quotas.profiles.delete",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\WorkQuotaController@postDeleteProfile"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/work-quotas\/agendas\/load\/{agendaId}",
            "name": "planner.configuration.work-quotas.agendas.load",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\WorkQuotaController@getAgenda"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/configuration\/work-quotas\/agendas\/save",
            "name": "planner.configuration.work-quotas.agendas.save",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\WorkQuotaController@postAgenda"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/configuration\/work-quotas\/agendas\/delete\/{agendaId}",
            "name": "planner.configuration.work-quotas.agendas.delete",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\WorkQuotaController@postDeleteAgenda"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/availability-calendar\/delete\/special\/date\/{specialDateId}",
            "name": "planner.configuration.availabilityCalendar.delete.special.date",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\AvailabilityCalendarController@getDeleteSpecialDate"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/availability-calendar\/create\/special\/date\/{specialDateId?}",
            "name": "planner.configuration.availabilityCalendar.modify.special.date",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\AvailabilityCalendarController@getModifySpecialDate"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/configuration\/availability-calendar\/save\/special\/date\/{specialDateId?}",
            "name": "planner.configuration.availabilityCalendar.save.special.date",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\AvailabilityCalendarController@postCreateSpecialDate"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/availability-calendar\/popup\/{employeeId}",
            "name": "planner.configuration.availabilityCalendar.popup",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\AvailabilityCalendarController@getPopup"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/ratings\/manage",
            "name": "planner.configuration.ratings.manage",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\RatingsController@manageCriteria"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/configuration\/ratings\/add-edit-criterion\/{criterionId?}",
            "name": "planner.configuration.ratings.addEditCriterion",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\RatingsController@addEditCriterion"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/configuration\/ratings\/store-update-criterion",
            "name": "planner.configuration.ratings.storeUpdateCriterion",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\RatingsController@storeUpdateCriterion"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/configuration\/ratings\/delete-criterion",
            "name": "planner.configuration.ratings.deleteCriterion",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\RatingsController@deleteCriterion"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/configuration\/ratings\/restore-criterion",
            "name": "planner.configuration.ratings.restoreCriterion",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\RatingsController@restoreCriterion"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/configuration\/ratings\/sort-criteria",
            "name": "planner.configuration.ratings.sortCriteria",
            "action": "StaffCloud\Applications\Planner\Modules\Configuration\Http\Controllers\RatingsController@sortCriteria"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/datamanagement\/employees\/list",
            "name": "planner.datamanagement.employees.list.employees",
            "action": "StaffCloud\Applications\Planner\Modules\DataManagement\Http\Controllers\EmployeesController@getList"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/datamanagement\/employees\/list\/{type}",
            "name": "planner.datamanagement.employees.list.applicantsAndCandidates",
            "action": "StaffCloud\Applications\Planner\Modules\DataManagement\Http\Controllers\EmployeesController@getList"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/datamanagement\/external-workers\/list",
            "name": "planner.datamanagement.externalWorkers.list",
            "action": "StaffCloud\Applications\Planner\Modules\DataManagement\Http\Controllers\ExternalStaffProvidersController@getApp"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/datamanagement\/external-staff-providers\/list",
            "name": "planner.datamanagement.externalStaffProviders.list",
            "action": "StaffCloud\Applications\Planner\Modules\DataManagement\Http\Controllers\ExternalStaffProvidersController@getApp"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/datamanagement\/employees\/get\/{employmentType?}",
            "name": "planner.datamanagement.employees.get",
            "action": "StaffCloud\Applications\Planner\Modules\DataManagement\Http\Controllers\EmployeesController@getEmployees"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/datamanagement\/external-staff-providers\/getExternalProviders",
            "name": "planner.datamanagement.externalStaffProviders.getExternalProviders",
            "action": "StaffCloud\Applications\Planner\Modules\DataManagement\Http\Controllers\ExternalStaffProvidersController@getExternalProviders"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/datamanagement\/employees\/popup",
            "name": "planner.datamanagement.employees.popup",
            "action": "StaffCloud\Applications\Planner\Modules\DataManagement\Http\Controllers\EmployeesController@getPopup"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/datamanagement\/external-workers\/popup",
            "name": "planner.datamanagement.externalWorkers.popup",
            "action": "StaffCloud\Applications\Planner\Modules\DataManagement\Http\Controllers\ExternalWorkersController@getPopup"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/datamanagement\/external-staff-providers\/popup",
            "name": "planner.datamanagement.externalStaffProviders.popup",
            "action": "StaffCloud\Applications\Planner\Modules\DataManagement\Http\Controllers\ExternalStaffProvidersController@getPopup"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/datamanagement\/employees\/internal-work-quotas",
            "name": "planner.datamanagement.employees.internalWorkQuotas",
            "action": "StaffCloud\Applications\Planner\Modules\DataManagement\Http\Controllers\EmployeesController@getWorkQuotasTable"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/datamanagement\/employees\/remind",
            "name": "planner.datamanagement.employees.remind",
            "action": "StaffCloud\Applications\Planner\Modules\DataManagement\Http\Controllers\EmployeesController@postRemind"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/datamanagement\/employees\/fetch-all-by-events",
            "name": "planner.datamanagement.employees.eventsEmployees",
            "action": "StaffCloud\Applications\Planner\Modules\DataManagement\Http\Controllers\EmployeesController@getEmployeesByEvents"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/datamanagement\/external-workers\/new",
            "name": "planner.datamanagement.externalWorkers.new",
            "action": "StaffCloud\Applications\Planner\Modules\DataManagement\Http\Controllers\ExternalWorkersController@getNewExternalWorker"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/datamanagement\/employees\/save\/{employmentType?}",
            "name": "planner.datamanagement.employees.save",
            "action": "StaffCloud\Applications\Planner\Modules\DataManagement\Http\Controllers\EmployeesController@postSave"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/datamanagement\/external-workers\/save\/{employmentType?}",
            "name": "planner.datamanagement.externalWorkers.save",
            "action": "StaffCloud\Applications\Planner\Modules\DataManagement\Http\Controllers\ExternalWorkersController@postSave"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/datamanagement\/employees\/set-status",
            "name": "planner.datamanagement.employees.setStatus",
            "action": "StaffCloud\Applications\Planner\Modules\DataManagement\Http\Controllers\EmployeesController@postSetStatus"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/datamanagement\/external-staff-providers\/set-status",
            "name": "planner.datamanagement.externalStaffProviders.setStatus",
            "action": "StaffCloud\Applications\Planner\Modules\DataManagement\Http\Controllers\ExternalStaffProvidersController@postSetStatus"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/datamanagement\/employees\/delete-picture",
            "name": "planner.datamanagement.employees.deletePicture",
            "action": "StaffCloud\Applications\Planner\Modules\DataManagement\Http\Controllers\EmployeesController@getDeletePicture"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/datamanagement\/employees\/change-pictures-visibility",
            "name": "planner.datamanagement.employees.changePicturesVisibility",
            "action": "StaffCloud\Applications\Planner\Modules\DataManagement\Http\Controllers\EmployeesController@getChangePicturesVisibility"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/datamanagement\/employees\/review",
            "name": "planner.datamanagement.employees.review",
            "action": "StaffCloud\Applications\Planner\Modules\DataManagement\Http\Controllers\EmployeesController@postReview"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/datamanagement\/employees\/delete",
            "name": "planner.datamanagement.employees.delete",
            "action": "StaffCloud\Applications\Planner\Modules\DataManagement\Http\Controllers\EmployeesController@postDelete"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/datamanagement\/employees\/mandatory-fields",
            "name": "planner.datamanagement.employees.getMandatoryFields",
            "action": "StaffCloud\Applications\Planner\Modules\DataManagement\Http\Controllers\EmployeesController@postGetMandatoryFields"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/datamanagement\/employees\/send-login-information",
            "name": "planner.datamanagement.employees.sendLoginInformation",
            "action": "StaffCloud\Applications\Planner\Modules\DataManagement\Http\Controllers\EmployeesController@postSendLoginInformation"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/datamanagement\/employees\/multi-edit\/{tab}",
            "name": "planner.datamanagement.employees.multiEdit",
            "action": "StaffCloud\Applications\Planner\Modules\DataManagement\Http\Controllers\EmployeesController@getMultiEdit"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/datamanagement\/external-workers\/multi-edit\/{tab}",
            "name": "planner.datamanagement.externalWorkers.multiEdit",
            "action": "StaffCloud\Applications\Planner\Modules\DataManagement\Http\Controllers\ExternalWorkersController@getMultiEdit"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/datamanagement\/external-staff-providers\/multi-edit",
            "name": "planner.datamanagement.externalStaffProviders.multiEdit",
            "action": "StaffCloud\Applications\Planner\Modules\DataManagement\Http\Controllers\ExternalStaffProvidersController@getMultiEdit"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/datamanagement\/external-staff-providers\/save-multi-edit",
            "name": "planner.datamanagement.externalStaffProviders.saveMultiEdit",
            "action": "StaffCloud\Applications\Planner\Modules\DataManagement\Http\Controllers\ExternalStaffProvidersController@postSaveMultiEdit"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/datamanagement\/employees\/save-multi-edit\/data",
            "name": "planner.datamanagement.employees.multiEdit.saveData",
            "action": "StaffCloud\Applications\Planner\Modules\DataManagement\Http\Controllers\EmployeesController@postSaveMultiEditData"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/datamanagement\/employees\/save-multi-edit\/internal",
            "name": "planner.datamanagement.employees.multiEdit.internal.saveData",
            "action": "StaffCloud\Applications\Planner\Modules\DataManagement\Http\Controllers\EmployeesController@postSaveMultiEditInternalData"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/datamanagement\/employees\/multi-edit\/internal\/save-work-quota-profiles",
            "name": "planner.datamanagement.employees.multiEdit.internal.saveWorkQuotaProfiles",
            "action": "StaffCloud\Applications\Planner\Modules\DataManagement\Http\Controllers\EmployeesController@postSaveMultiEditWorkQuotaProfiles"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/datamanagement\/activity-log\/search",
            "name": "planner.datamanagement.activityLog.search",
            "action": "StaffCloud\Applications\Planner\Modules\DataManagement\Http\Controllers\ActivityLogController@postSearch"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/datamanagement\/clients\/list",
            "name": "planner.datamanagement.clients.list",
            "action": "StaffCloud\Applications\Planner\Modules\DataManagement\Http\Controllers\ClientsController@getClientsContacts"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/datamanagement\/clients\/get",
            "name": "planner.datamanagement.clients.get",
            "action": "StaffCloud\Applications\Planner\Modules\DataManagement\Http\Controllers\ClientsController@getClients"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/datamanagement\/clients\/popup",
            "name": "planner.datamanagement.clients.popup",
            "action": "StaffCloud\Applications\Planner\Modules\DataManagement\Http\Controllers\ClientsController@getPopup"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/datamanagement\/clients\/{clientId}\/contacts",
            "name": "planner.datamanagement.clients.getClientContacts",
            "action": "StaffCloud\Applications\Planner\Modules\DataManagement\Http\Controllers\ClientsController@getClientContacts"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/datamanagement\/clients\/set-contact-roles",
            "name": "planner.datamanagement.clients.setContactRoles",
            "action": "StaffCloud\Applications\Planner\Modules\DataManagement\Http\Controllers\ClientsController@setContactRoles"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/datamanagement\/clients\/set-status",
            "name": "planner.datamanagement.clients.setStatus",
            "action": "StaffCloud\Applications\Planner\Modules\DataManagement\Http\Controllers\ClientsController@postSetStatus"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/datamanagement\/clients\/multi-edit",
            "name": "planner.datamanagement.clients.multiEdit",
            "action": "StaffCloud\Applications\Planner\Modules\DataManagement\Http\Controllers\ClientsController@getMultiEdit"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/datamanagement\/clients\/save-multi-edit",
            "name": "planner.datamanagement.clients.saveMultiEdit",
            "action": "StaffCloud\Applications\Planner\Modules\DataManagement\Http\Controllers\ClientsController@postSaveMultiEdit"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/datamanagement\/contacts\/list",
            "name": "planner.datamanagement.contacts.list",
            "action": "StaffCloud\Applications\Planner\Modules\DataManagement\Http\Controllers\ClientsController@getClientsContacts"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/datamanagement\/contacts\/get",
            "name": "planner.datamanagement.contacts.get",
            "action": "StaffCloud\Applications\Planner\Modules\DataManagement\Http\Controllers\ContactsController@getContacts"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/datamanagement\/contacts\/popup",
            "name": "planner.datamanagement.contacts.popup",
            "action": "StaffCloud\Applications\Planner\Modules\DataManagement\Http\Controllers\ContactsController@getPopup"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/datamanagement\/contacts\/set-status",
            "name": "planner.datamanagement.contacts.setStatus",
            "action": "StaffCloud\Applications\Planner\Modules\DataManagement\Http\Controllers\ContactsController@postSetStatus"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/datamanagement\/contacts\/multi-edit",
            "name": "planner.datamanagement.contacts.multiEdit",
            "action": "StaffCloud\Applications\Planner\Modules\DataManagement\Http\Controllers\ContactsController@getMultiEdit"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/datamanagement\/contacts\/save-multi-edit",
            "name": "planner.datamanagement.contacts.saveMultiEdit",
            "action": "StaffCloud\Applications\Planner\Modules\DataManagement\Http\Controllers\ContactsController@postSaveMultiEdit"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/datasync\/integrations",
            "name": "planner.datasync.basicIntegrations",
            "action": "StaffCloud\Applications\Planner\Modules\DataSync\Http\Controllers\DataSyncController@getBasicIntegrationsData"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/datasync\/integrationData\/{provider}",
            "name": "planner.datasync.integrationData",
            "action": "StaffCloud\Applications\Planner\Modules\DataSync\Http\Controllers\DataSyncController@getIntegrationData"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/datasync\/index",
            "name": "planner.datasync.index",
            "action": "StaffCloud\Applications\Planner\Modules\DataSync\Http\Controllers\DataSyncController@getIndex"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/datasync\/integration\/{integrationId}\/{integrationType?}\/{runId?}",
            "name": "planner.datasync.integration",
            "action": "StaffCloud\Applications\Planner\Modules\DataSync\Http\Controllers\DataSyncController@getIndex"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/datasync\/runs\/{provider}\/{type}\/{lastLoadedRunId?}",
            "name": "planner.datasync.get-runs",
            "action": "StaffCloud\Applications\Planner\Modules\DataSync\Http\Controllers\DataSyncController@postGetRuns"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/datasync\/run-and-logs\/{runId}\/{lastLoadedLogId?}",
            "name": "planner.datasync.get-run-and-logs",
            "action": "StaffCloud\Applications\Planner\Modules\DataSync\Http\Controllers\DataSyncController@postGetRunAndLogs"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/datasync\/run",
            "name": "planner.datasync.run",
            "action": "StaffCloud\Applications\Planner\Modules\DataSync\Http\Controllers\DataSyncController@postRun"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/datasync\/config\/add-or-change",
            "name": "planner.datasync.config.add-or-change",
            "action": "StaffCloud\Applications\Planner\Modules\DataSync\Http\Controllers\IntegrationConfigurationController@postAddOrChange"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/datasync\/config\/list",
            "name": "planner.datasync.config.list",
            "action": "StaffCloud\Applications\Planner\Modules\DataSync\Http\Controllers\IntegrationConfigurationController@getAll"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/datasync\/config\/get",
            "name": "planner.datasync.config.get",
            "action": "StaffCloud\Applications\Planner\Modules\DataSync\Http\Controllers\IntegrationConfigurationController@getByIntegration"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/datasync\/yousign\/electronic-seal-images",
            "name": "planner.datasync.yousign.upload-electronic-seal-images",
            "action": "StaffCloud\Applications\Planner\Modules\DataSync\Http\Controllers\IntegrationConfigurationController@uploadYouSignElectronicSealImages"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/datasync\/yousign\/electronic-seal-images",
            "name": "planner.datasync.yousign.list-electronic-seal-images",
            "action": "StaffCloud\Applications\Planner\Modules\DataSync\Http\Controllers\IntegrationConfigurationController@listYouSignElectronicSealImages"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/datasync\/yousign\/delete-electronic-seal-image",
            "name": "planner.datasync.yousign.delete-electronic-seal-image",
            "action": "StaffCloud\Applications\Planner\Modules\DataSync\Http\Controllers\IntegrationConfigurationController@deleteYouSignElectronicSealImage"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/datasync\/yousign\/download-electronic-seal-image\/{id}\/{name}",
            "name": "planner.datasync.yousign.download-electronic-seal-image",
            "action": "StaffCloud\Applications\Planner\Modules\DataSync\Http\Controllers\IntegrationConfigurationController@downloadYouSignElectronicSealImage"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/downloads\/list",
            "name": "planner.downloads.list",
            "action": "StaffCloud\Applications\Planner\Modules\Downloads\Http\Controllers\DownloadsController@getList"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/downloads\/page",
            "name": "planner.downloads.page",
            "action": "StaffCloud\Applications\Planner\Modules\Downloads\Http\Controllers\DownloadsController@getPage"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/downloads\/delete",
            "name": "planner.downloads.delete",
            "action": "StaffCloud\Applications\Planner\Modules\Downloads\Http\Controllers\DownloadsController@postDelete"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/export\/overview",
            "name": "planner.export.overview",
            "action": "StaffCloud\Applications\Planner\Modules\Export\Http\Controllers\ExportController@getOverview"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/export\/templates",
            "name": "planner.export.templates",
            "action": "StaffCloud\Applications\Planner\Modules\Export\Http\Controllers\ExportController@getTemplates"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/export\/templates\/context\/{context}",
            "name": "planner.export.contextTemplatesList",
            "action": "StaffCloud\Applications\Planner\Modules\Export\Http\Controllers\ActionsController@getContextTemplatesList"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/export\/download-template-data",
            "name": "planner.export.getDownloadTemplateData",
            "action": "StaffCloud\Applications\Planner\Modules\Export\Http\Controllers\ActionsController@postGetDownloadTemplateData"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/export\/download\/{format}",
            "name": "planner.export.download",
            "action": "StaffCloud\Applications\Planner\Modules\Export\Http\Controllers\ActionsController@postDownload"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/export\/search-field-configurations",
            "name": "planner.export.searchFieldConfigurations",
            "action": "StaffCloud\Applications\Planner\Modules\Export\Http\Controllers\ActionsController@getSearchFieldConfigurations"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/export\/search-form\/{resourceIdentifier}",
            "name": "planner.export.search",
            "action": "StaffCloud\Applications\Planner\Modules\Export\Http\Controllers\ActionsController@postSearch"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/export\/preview",
            "name": "planner.export.preview",
            "action": "StaffCloud\Applications\Planner\Modules\Export\Http\Controllers\ActionsController@postPreview"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/export\/resource-definitions",
            "name": "planner.export.resourceDefinitions",
            "action": "StaffCloud\Applications\Planner\Modules\Export\Http\Controllers\ActionsController@getResourceDefinitions"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/export\/{resourceIdentifier}",
            "name": "planner.export.index",
            "action": "StaffCloud\Applications\Planner\Modules\Export\Http\Controllers\ExportController@getIndex"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/export\/edit\/{resourceIdentifier}",
            "name": "planner.export.edit",
            "action": "StaffCloud\Applications\Planner\Modules\Export\Http\Controllers\ExportController@getEdit"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/export\/erp\/integration",
            "name": "planner.export.erp.integration",
            "action": "StaffCloud\Applications\Planner\Modules\Export\Http\Controllers\ErpIntegrationController@getIntegration"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/export\/templates\/resource\/{baseResource}",
            "name": "planner.export.resourceTemplatesList",
            "action": "StaffCloud\Applications\Planner\Modules\Export\Http\Controllers\ActionsController@getResourceTemplatesList"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/export\/templates\/contexts",
            "name": "planner.export.contextsList",
            "action": "StaffCloud\Applications\Planner\Modules\Export\Http\Controllers\ActionsController@getContextsList"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/export\/templates\/load\/{templateId}",
            "name": "planner.export.loadTemplate",
            "action": "StaffCloud\Applications\Planner\Modules\Export\Http\Controllers\ActionsController@getLoadTemplate"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/export\/templates\/save",
            "name": "planner.export.saveTemplate",
            "action": "StaffCloud\Applications\Planner\Modules\Export\Http\Controllers\ActionsController@postSaveTemplate"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/export\/templates\/delete",
            "name": "planner.export.deleteTemplate",
            "action": "StaffCloud\Applications\Planner\Modules\Export\Http\Controllers\ActionsController@postDeleteTemplate"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/finance\/pay-runs",
            "name": "planner.finance.payRuns.index",
            "action": "StaffCloud\Applications\Planner\Modules\Finance\Http\Controllers\PayRunsController@getIndex"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/finance\/pay-runs\/opened",
            "name": "planner.finance.payRuns.getOpenedPayRuns",
            "action": "StaffCloud\Applications\Planner\Modules\Finance\Http\Controllers\PayRunsController@getOpenedPayRuns"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/finance\/pay-runs\/pay-lines",
            "name": "planner.finance.payRuns.getPayRunsPayLines",
            "action": "StaffCloud\Applications\Planner\Modules\Finance\Http\Controllers\PayRunsController@getPayRunsPayLines"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/finance\/pay-runs\/create-new-pay-run",
            "name": "planner.finance.payRuns.createNewPayRun",
            "action": "StaffCloud\Applications\Planner\Modules\Finance\Http\Controllers\PayRunsController@postBuildNewPayRun"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/finance\/pay-runs\/add-to-pay-run",
            "name": "planner.finance.payRuns.addToPayRun",
            "action": "StaffCloud\Applications\Planner\Modules\Finance\Http\Controllers\PayRunsController@postAddToPayRun"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/finance\/pay-runs\/get-pay-lines-for-assignments",
            "name": "planner.finance.payRuns.getPayLinesForAssignments",
            "action": "StaffCloud\Applications\Planner\Modules\Finance\Http\Controllers\PayRunsController@postGetPayLinesForAssignments"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/finance\/pay-runs\/statistics",
            "name": "planner.finance.payRuns.getPayRunsStatistics",
            "action": "StaffCloud\Applications\Planner\Modules\Finance\Http\Controllers\PayRunsController@postGetPayRunsStatistics"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/finance\/pay-runs\/{payRunId}\/pay",
            "name": "planner.finance.payRuns.payPayRun",
            "action": "StaffCloud\Applications\Planner\Modules\Finance\Http\Controllers\PayRunsController@getPayPayRun"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/finance\/pay-runs\/delete",
            "name": "planner.finance.payRuns.deletePayRuns",
            "action": "StaffCloud\Applications\Planner\Modules\Finance\Http\Controllers\PayRunsController@postDeletePayRuns"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/finance\/pay-runs\/delete-pay-lines",
            "name": "planner.finance.payRuns.deletePayLines",
            "action": "StaffCloud\Applications\Planner\Modules\Finance\Http\Controllers\PayRunsController@postDeletePayLines"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/finance\/pay-runs\/delete-pay-lines-by-assignments-ids",
            "name": "planner.finance.payRuns.deletePayLinesByAssignmentsIds",
            "action": "StaffCloud\Applications\Planner\Modules\Finance\Http\Controllers\PayRunsController@postDeletePayLinesByAssignmentsIds"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/finance\/pay-runs\/{payRunId}\/set-as-exported",
            "name": "planner.finance.payRuns.setPayRunAsExported",
            "action": "StaffCloud\Applications\Planner\Modules\Finance\Http\Controllers\PayRunsController@getSetPayRunAsExported"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/projectmanagement\/main\/fetch-entities",
            "name": "planner.projectmanagement.main.fetchEntities",
            "action": "StaffCloud\Applications\Planner\Modules\ProjectManagement\Http\Controllers\MainController@fetchEntities"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/projectmanagement\/main\/projects\/edit",
            "name": "planner.projectmanagement.main.projects.edit",
            "action": "StaffCloud\Applications\Planner\Modules\ProjectManagement\Http\Controllers\MainController@getEditProjects"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/projectmanagement\/main\/events\/edit-details",
            "name": "planner.projectmanagement.main.events.edit-details",
            "action": "StaffCloud\Applications\Planner\Modules\ProjectManagement\Http\Controllers\MainController@getEditEventsDetails"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/projectmanagement\/settings\/edit",
            "name": "planner.projectmanagement.settings.edit",
            "action": "StaffCloud\Applications\Planner\Modules\ProjectManagement\Http\Controllers\SettingsController@getEditSettings"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/projectmanagement\/reporting\/fetch-entities",
            "name": "planner.projectmanagement.reporting.fetchEntities",
            "action": "StaffCloud\Applications\Planner\Modules\ProjectManagement\Http\Controllers\ReportingController@postFetchEntities"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/projectmanagement\/reporting\/remote-refresh",
            "name": "planner.projectmanagement.reporting.remoteRefresh",
            "action": "StaffCloud\Applications\Planner\Modules\ProjectManagement\Http\Controllers\ReportingController@postRemoteRefresh"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/projectmanagement\/team\/fetch-functions",
            "name": "planner.projectmanagement.team.fetchFunctions",
            "action": "StaffCloud\Applications\Planner\Modules\ProjectManagement\Http\Controllers\TeamController@postFetchFunctions"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/projectmanagement\/team\/fetch-shifts",
            "name": "planner.projectmanagement.team.fetchShifts",
            "action": "StaffCloud\Applications\Planner\Modules\ProjectManagement\Http\Controllers\TeamController@postFetchShifts"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/projectmanagement\/team\/fetch-collections",
            "name": "planner.projectmanagement.team.fetchCollections",
            "action": "StaffCloud\Applications\Planner\Modules\ProjectManagement\Http\Controllers\TeamController@postFetchCollections"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/projectmanagement\/duplication\/{type}",
            "name": "planner.projectmanagement.duplication",
            "action": "StaffCloud\Applications\Planner\Modules\ProjectManagement\Http\Controllers\DuplicationController@getForm"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/projectmanagement\/view\/active",
            "name": "planner.projectmanagement.view.active",
            "action": "StaffCloud\Applications\Planner\Modules\ProjectManagement\Http\Controllers\ViewController@getActive"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/projectmanagement\/view\/drafts",
            "name": "planner.projectmanagement.view.drafts",
            "action": "StaffCloud\Applications\Planner\Modules\ProjectManagement\Http\Controllers\ViewController@getDrafts"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/projectmanagement\/view\/archived",
            "name": "planner.projectmanagement.view.archived",
            "action": "StaffCloud\Applications\Planner\Modules\ProjectManagement\Http\Controllers\ViewController@getArchived"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/projectmanagement\/view\/aborted",
            "name": "planner.projectmanagement.view.aborted",
            "action": "StaffCloud\Applications\Planner\Modules\ProjectManagement\Http\Controllers\ViewController@getAborted"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/projectmanagement\/view\/search",
            "name": "planner.projectmanagement.view.search",
            "action": "StaffCloud\Applications\Planner\Modules\ProjectManagement\Http\Controllers\ViewController@getSearch"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/projectmanagement\/view\/events",
            "name": "planner.projectmanagement.view.events",
            "action": "StaffCloud\Applications\Planner\Modules\ProjectManagement\Http\Controllers\ViewController@postGetEvents"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/projectmanagement\/view\/search\/projects",
            "name": "planner.projectmanagement.view.search.projects",
            "action": "StaffCloud\Applications\Planner\Modules\ProjectManagement\Http\Controllers\ViewController@postSearchProjects"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/projectmanagement\/view\/search\/events",
            "name": "planner.projectmanagement.view.search.events",
            "action": "StaffCloud\Applications\Planner\Modules\ProjectManagement\Http\Controllers\ViewController@postSearchEvents"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/projectmanagement\/main\/reporting-forms\/save",
            "name": "planner.projectmanagement.reporting.save",
            "action": "StaffCloud\Applications\Planner\Modules\ProjectManagement\Http\Controllers\ReportingController@postSave"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/projectmanagement\/event\/save",
            "name": "planner.projectmanagement.event.save",
            "action": "StaffCloud\Applications\Planner\Modules\ProjectManagement\Http\Controllers\EventController@postSaveEvents"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/projectmanagement\/event\/activate",
            "name": "planner.projectmanagement.event.activate",
            "action": "StaffCloud\Applications\Planner\Modules\ProjectManagement\Http\Controllers\EventController@postActivateEvents"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/projectmanagement\/event\/archive",
            "name": "planner.projectmanagement.event.archive",
            "action": "StaffCloud\Applications\Planner\Modules\ProjectManagement\Http\Controllers\EventController@postArchiveEvents"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/projectmanagement\/project\/archive",
            "name": "planner.projectmanagement.project.archive",
            "action": "StaffCloud\Applications\Planner\Modules\ProjectManagement\Http\Controllers\ProjectController@postArchiveProjects"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/projectmanagement\/delete",
            "name": "planner.projectmanagement.delete",
            "action": "StaffCloud\Applications\Planner\Modules\ProjectManagement\Http\Controllers\ViewController@postDelete"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/projectmanagement\/team\/delete-shift",
            "name": "planner.projectmanagement.team.deleteShift",
            "action": "StaffCloud\Applications\Planner\Modules\ProjectManagement\Http\Controllers\TeamController@postDeleteShift"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/projectmanagement\/team\/save",
            "name": "planner.projectmanagement.team.save",
            "action": "StaffCloud\Applications\Planner\Modules\ProjectManagement\Http\Controllers\TeamController@postSave"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/projectmanagement\/notification\/send",
            "name": "planner.projectmanagement.notification.send",
            "action": "StaffCloud\Applications\Planner\Modules\ProjectManagement\Http\Controllers\NotificationController@postSend"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/projectmanagement\/project\/abort",
            "name": "planner.projectmanagement.project.abort",
            "action": "StaffCloud\Applications\Planner\Modules\ProjectManagement\Http\Controllers\ProjectController@postAbortProjects"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/projectmanagement\/event\/abort",
            "name": "planner.projectmanagement.event.abort",
            "action": "StaffCloud\Applications\Planner\Modules\ProjectManagement\Http\Controllers\EventController@postAbortEvents"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/staffplanning\/overview\/add-project",
            "name": "planner.staffplanning.overview.add-project",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\MainController@getIndex"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/staffplanning\/overview\/active",
            "name": "planner.staffplanning.overview.active",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\MainController@getIndex"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/staffplanning\/overview\/draft",
            "name": "planner.staffplanning.overview.draft",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\MainController@getIndex"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/staffplanning\/overview\/aborted",
            "name": "planner.staffplanning.overview.aborted",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\MainController@getIndex"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/staffplanning\/overview\/archived",
            "name": "planner.staffplanning.overview.archived",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\MainController@getIndex"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/staffplanning\/staff-planning",
            "name": "planner.staffplanning.staffPlanning",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\MainController@getIndex"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/staffplanning\/invitation-list",
            "name": "planner.staffplanning.invitationList",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\MainController@getIndex"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/staffplanning\/team-sheet",
            "name": "planner.staffplanning.teamSheet",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\MainController@getIndex"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/staffplanning\/contracts",
            "name": "planner.staffplanning.contracts",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\MainController@getIndex"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/staffplanning\/elda",
            "name": "planner.staffplanning.elda",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\MainController@getIndex"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/staffplanning\/live-tab",
            "name": "planner.staffplanning.liveTab",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\MainController@getIndex"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/staffplanning\/work-data",
            "name": "planner.staffplanning.workData",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\MainController@getIndex"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/staffplanning\/dynamic-form",
            "name": "planner.staffplanning.dynamicForm",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\MainController@getIndex"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/location",
            "name": "planner.staffplanning.location",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\MainController@postLocation"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/filter-bar\/filter-data",
            "name": "planner.staffplanning.filterBar.filterData",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\FilterBarController@postFilterData"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/filter-bar\/filtered-projects-events-and-event-functions",
            "name": "planner.staffplanning.filterBar.filteredProjectsEventsAndEventFunctions",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\FilterBarController@postFilteredProjectsEventsAndEventFunctions"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/overview\/projects",
            "name": "planner.staffplanning.overview.projects",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\OverviewController@postProjects"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/overview\/project-events",
            "name": "planner.staffplanning.overview.projectEvents",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\OverviewController@postProjectEvents"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/overview\/search-projects",
            "name": "planner.staffplanning.overview.searchProjects",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\OverviewController@postSearchProjects"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/staffplanning\/invitation-list\/search-forms",
            "name": "planner.staffplanning.invitationList.searchForms",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\InvitationListController@getSearchForms"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/invitation-list\/employees",
            "name": "planner.staffplanning.invitationList.employees",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\InvitationListController@postGetEmployees"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/invitation-list\/get-verifiers",
            "name": "planner.staffplanning.invitationList.getVerifiers",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\InvitationListController@postGetVerifiers"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/invitation-list\/invitation-list",
            "name": "planner.staffplanning.invitationList.invitationList",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\InvitationListController@postGetInvitationList"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/staffplanning\/invitation-list\/assignments",
            "name": "planner.staffplanning.invitationList.assignments",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\InvitationListController@getAssignments"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/staff-planning\/projects-events",
            "name": "planner.staffplanning.staffPlanning.projectsEvents",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\StaffPlanningController@postGetProjectsEvents"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/staff-planning\/employees-assignments",
            "name": "planner.staffplanning.staffPlanning.employeesAssignments",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\StaffPlanningController@postGetEmployeesAssignments"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/staff-planning\/employees-availabilities",
            "name": "planner.staffplanning.staffPlanning.employeesAvailabilities",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\StaffPlanningController@postGetEmployeesAvailabilities"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/staff-planning\/get-verifiers",
            "name": "planner.staffplanning.staffPlanning.getVerifiers",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\StaffPlanningController@postGetVerifiers"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/staff-planning\/distances",
            "name": "planner.staffplanning.staffPlanning.distances",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\StaffPlanningController@postGetDistances"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/staff-planning\/work-quotas",
            "name": "planner.staffplanning.staffPlanning.workQuotas",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\StaffPlanningController@postGetEmployeesWorkQuotas"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/staff-planning\/employees-conflicts-collisions",
            "name": "planner.staffplanning.staffPlanning.employeesConflictsCollisions",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\StaffPlanningController@postGetEmployeesConflictsCollisions"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/staff-planning\/get-tempcloud-customer-portal-sync-data",
            "name": "planner.staffplanning.staffPlanning.getTempcloudCustomerPortalSyncData",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\StaffPlanningController@postGetTempcloudCustomerPortalSyncData"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/staffplanning\/staff-planning\/conflict-details",
            "name": "planner.staffplanning.staffPlanning.conflictDetails",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\StaffPlanningController@getConflictDetails"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/staffplanning\/staff-planning\/collision-details",
            "name": "planner.staffplanning.staffPlanning.collisionDetails",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\StaffPlanningController@getCollisionDetails"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/staff-planning-calendar\/employees-filters",
            "name": "planner.staffplanning.staffPlanningCalendar.getEmployeesFilters",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\StaffPlanningCalendarController@postGetEmployeesFilters"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/staff-planning-calendar\/events-functions",
            "name": "planner.staffplanning.staffPlanningCalendar.getEventsFunctions",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\StaffPlanningCalendarController@postGetEventsFunctions"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/staff-planning-calendar\/employees",
            "name": "planner.staffplanning.staffPlanningCalendar.getEmployees",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\StaffPlanningCalendarController@postGetEmployees"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/staff-planning-calendar\/busy-periods",
            "name": "planner.staffplanning.staffPlanningCalendar.getBusyPeriods",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\StaffPlanningCalendarController@postGetBusyPeriods"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/staff-planning-calendar\/assignments",
            "name": "planner.staffplanning.staffPlanningCalendar.getAssignments",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\StaffPlanningCalendarController@postGetAssignments"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/staff-planning-calendar\/get-verifiers",
            "name": "planner.staffplanning.staffPlanningCalendar.getVerifiers",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\StaffPlanningCalendarController@postGetVerifiers"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/staff-planning-calendar\/employees-conflicts-collisions",
            "name": "planner.staffplanning.staffPlanningCalendar.employeesConflictsCollisions",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\StaffPlanningCalendarController@postGetEmployeesConflictsCollisions"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/staff-planning-calendar\/work-quotas",
            "name": "planner.staffplanning.staffPlanningCalendar.getWorkQuotas",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\StaffPlanningCalendarController@postGetWorkQuotas"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/team-sheet\/event-assignments",
            "name": "planner.staffplanning.teamSheet.eventAssignments",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\TeamSheetController@postGetEventAssignments"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/team-sheet\/pdf",
            "name": "planner.staffplanning.teamSheet.print",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\TeamSheetController@postDownloadPdf"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/team-sheet\/update-assignment-work-times",
            "name": "planner.staffplanning.teamSheet.updateAssignmentWorkTimes",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\TeamSheetController@updateAssignmentWorkTimes"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/team-sheet\/state-transition",
            "name": "planner.staffplanning.teamSheet.stateTransition",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\TeamSheetController@postStateTransition"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/team-sheet\/planner-remarks\/delete",
            "name": "planner.staffplanning.teamSheet.deletePlannerRemarks",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\TeamSheetController@postDeletePlannerRemarks"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/team-sheet\/planner-remarks",
            "name": "planner.staffplanning.teamSheet.createUpdatePlannerRemarks",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\TeamSheetController@postCreateUpdatePlannerRemarks"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/team-sheet\/external-staff-assignment",
            "name": "planner.staffplanning.teamSheet.createUpdateExternalStaffAssignment",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\TeamSheetController@postCreateUpdateExternalStaffAssignment"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/staffplanning\/team-sheet\/external-workers",
            "name": "planner.staffplanning.teamSheet.getExternalWorkers",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\TeamSheetController@getExternalWorkers"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/work-data\/event-assignments",
            "name": "planner.staffplanning.workData.eventAssignments",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\WorkDataController@postGetEventAssignments"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/work-data\/reporting-forms-data",
            "name": "planner.staffplanning.workData.getReportingFormsData",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\WorkDataController@postGetReportingFormsData"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/work-data\/assignments-pay-sheets",
            "name": "planner.staffplanning.workData.assignmentsPaysheets",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\WorkDataController@postAssignmentsPaysheets"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/work-data\/approve-pay-sheets",
            "name": "planner.staffplanning.workData.approvePaysheets",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\WorkDataController@postApproveAssignmentPaysheets"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/staffplanning\/work-data\/un-approve-pay-sheet\/{assignmentId}",
            "name": "planner.staffplanning.workData.unapprovePaysheet",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\WorkDataController@getUnapproveAssignmentPaysheet"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/staffplanning\/work-data\/un-approve-timesheet\/{assignmentId}",
            "name": "planner.staffplanning.workData.unapproveTimesheet",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\WorkDataController@getUnapproveAssignmentTimesheet"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/work-data\/reset-assignment-pay-sheet",
            "name": "planner.staffplanning.workData.resetAssignmentPaysheet",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\WorkDataController@postResetAssignmentPaysheet"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/staffplanning\/work-data\/all-wage-profiles",
            "name": "planner.staffplanning.workData.getAllWorkProfiles",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\WorkDataController@getAllWorkProfiles"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/staffplanning\/work-data\/download-signed-work-data\/{signatureId}",
            "name": "planner.staffplanning.workData.getDownloadSignedWorkData",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\WorkDataController@getDownloadSignedWorkData"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/staffplanning\/context-menu\/employee-form",
            "name": "planner.staffplanning.contextMenu.employeeForm",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\ContextMenuController@getEmployeeForm"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/staffplanning\/context-menu\/function-details",
            "name": "planner.staffplanning.contextMenu.functionDetails",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\ContextMenuController@getFunctionDetails"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/staffplanning\/context-menu\/event-form",
            "name": "planner.staffplanning.contextMenu.eventForm",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\ContextMenuController@getEventForm"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/staffplanning\/context-menu\/project-form",
            "name": "planner.staffplanning.contextMenu.projectForm",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\ContextMenuController@getProjectForm"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/live-tab\/event-assignments",
            "name": "planner.staffplanning.liveTab.eventAssignments",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\LiveTabController@postGetEventAssignments"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/staffplanning\/live-tab\/download-files\/{filesIds}",
            "name": "planner.staffplanning.liveTab.downloadFiles",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\LiveTabController@getDownloadFiles"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/contracts\/event-assignments",
            "name": "planner.staffplanning.contracts.eventAssignments",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\ContractsController@postGetEventAssignments"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/contracts\/pdf",
            "name": "planner.staffplanning.contracts.print",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\ContractsController@postDownloadPdf"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/contracts\/view",
            "name": "planner.staffplanning.contracts.view",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\ContractsController@postViewContract"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/contracts\/retry-signature-request",
            "name": "planner.staffplanning.contracts.retrySignatureRequest",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\ContractsController@postRetrySignatureRequest"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/contracts\/cancel-signature-request",
            "name": "planner.staffplanning.contracts.cancelSignatureRequest",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\ContractsController@postCancelSignatureRequest"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/contracts\/delete-signature-request",
            "name": "planner.staffplanning.contracts.deleteSignatureRequest",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\ContractsController@postDeleteSignatureRequest"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/contracts\/generate-contract\/get-dependencies",
            "name": "planner.staffplanning.contracts.generate-contract.get-dependencies",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\ContractsController@postGenerateContractDependencies"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/contracts\/generate-contract\/get-pdf-data",
            "name": "planner.staffplanning.contracts.generate-contract.get-pdf-data",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\ContractsController@postGetPdfData"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/contracts\/generate-contract\/send",
            "name": "planner.staffplanning.contracts.generate-contract.send",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\ContractsController@postSend"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/elda\/event-assignments",
            "name": "planner.staffplanning.elda.eventAssignments",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\EldaController@postGetEventAssignments"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/elda\/download-registration",
            "name": "planner.staffplanning.elda.download-registration",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\EldaController@postDownloadRegistration"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/elda\/download-report",
            "name": "planner.staffplanning.elda.download-report",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\EldaController@postDownloadReport"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/elda\/get-report",
            "name": "planner.staffplanning.elda.get-report",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\EldaController@postGetReport"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/elda\/generate-report",
            "name": "planner.staffplanning.elda.generate-report",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\EldaController@postSendEmploymentReport"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/elda\/cancel-report",
            "name": "planner.staffplanning.elda.cancel-report",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\EldaController@postCancelEmploymentReport"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/invitation-list\/state-transition",
            "name": "planner.staffplanning.invitationList.stateTransition",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\InvitationListController@postStateTransition"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/invitation-list\/auto-invite",
            "name": "planner.staffplanning.invitationList.autoInvite",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\InvitationListController@postAutoInvite"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/staff-planning\/assign-provisional-employee",
            "name": "planner.staffplanning.staffPlanning.assignProvisionalEmployee",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\StaffPlanningController@postAssignProvisionalEmployee"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/staff-planning\/assign-provisional-employee-to-multiple",
            "name": "planner.staffplanning.staffPlanning.assignProvisionalEmployeeToMultiple",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\StaffPlanningController@postAssignProvisionalEmployeeToMultiple"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/staff-planning\/assign-provisional-employees-to-multiple",
            "name": "planner.staffplanning.staffPlanning.assignProvisionalEmployeesToMultiple",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\StaffPlanningController@postAssignProvisionalEmployeesToMultiple"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/staff-planning\/move-employee",
            "name": "planner.staffplanning.staffPlanning.moveEmployee",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\StaffPlanningController@postMoveEmployee"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/staff-planning\/move-employee-to-multiple",
            "name": "planner.staffplanning.staffPlanning.moveEmployeeToMultiple",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\StaffPlanningController@postMoveEmployeeToMultiple"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/staff-planning\/un-assign-employees",
            "name": "planner.staffplanning.staffPlanning.unAssignEmployees",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\StaffPlanningController@postUnAssignEmployees"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/staff-planning\/deny-employees",
            "name": "planner.staffplanning.staffPlanning.denyEmployees",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\StaffPlanningController@postDenyEmployees"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/staff-planning\/deny-employees-in-functions",
            "name": "planner.staffplanning.staffPlanning.denyEmployeesInFunctions",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\StaffPlanningController@postDenyEmployeesInFunctions"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/staff-planning\/lock-event",
            "name": "planner.staffplanning.staffPlanning.lockEvent",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\StaffPlanningController@postLockEvent"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/staff-planning\/lock-function",
            "name": "planner.staffplanning.staffPlanning.lockFunction",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\StaffPlanningController@postLockFunction"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/staff-planning\/assign-employees",
            "name": "planner.staffplanning.staffPlanning.assignEmployees",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\StaffPlanningController@postAssignEmployees"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/staff-planning\/finish-events",
            "name": "planner.staffplanning.staffPlanning.finishEvents",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\StaffPlanningController@postFinishEvents"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/staff-planning\/update-work-times",
            "name": "planner.staffplanning.staffPlanning.updateWorkTimes",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\StaffPlanningController@postUpdateWorkTimes"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/work-data\/assignment-wage-data",
            "name": "planner.staffplanning.workData.assignmentsWageData",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\WorkDataController@postAssignmentsWageData"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/staffplanning\/work-data\/assignment-timesheet\/{assignmentId}",
            "name": "planner.staffplanning.workData.assignmentWorkTimes",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\WorkDataController@getAssignmentWorkTimes"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/work-data\/work-time\/{assignmentId}",
            "name": "planner.staffplanning.workData.workTime",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\WorkDataController@postWorkTime"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/work-data\/work-times",
            "name": "planner.staffplanning.workData.workTimes",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\WorkDataController@postWorkTimes"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/staffplanning\/work-data\/approve-timesheet\/{assignmentId}",
            "name": "planner.staffplanning.workData.approveTimesheet",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\WorkDataController@getApproveAssignmentTimesheet"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/work-data\/approve-timesheets",
            "name": "planner.staffplanning.workData.approveTimesheets",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\WorkDataController@postApproveAssignmentTimesheets"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/work-data\/reject-proposals",
            "name": "planner.staffplanning.workData.rejectProposals",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\WorkDataController@postRejectProposals"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/work-data\/accept-proposals",
            "name": "planner.staffplanning.workData.acceptProposals",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\WorkDataController@postAcceptProposals"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/work-data\/request-proposals-updates",
            "name": "planner.staffplanning.workData.requestProposalsUpdates",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\WorkDataController@postRequestProposalsUpdates"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "planner\/staffplanning\/work-data\/download-files\/{filesIds}",
            "name": "planner.staffplanning.workData.downloadFiles",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\WorkDataController@getDownloadFiles"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/work-data\/reset-time-sheet\/{assignmentId}",
            "name": "planner.staffplanning.workData.resetTimesheet",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\WorkDataController@postResetTimesheet"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/work-data\/modify-assignment-wage-profile",
            "name": "planner.staffplanning.workData.modifyAssignmentsWageProfiles",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\WorkDataController@postModifyAssignmentsWageProfiles"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/live-tab\/save-livestamp",
            "name": "planner.staffplanning.liveTab.saveLivestamp",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\LiveTabController@postSaveLivestamp"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/live-tab\/bulk-live-proposal",
            "name": "planner.staffplanning.liveTab.bulkLiveProposals",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\LiveTabController@postBulkLiveProposals"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "planner\/staffplanning\/live-tab\/save-live-proposal",
            "name": "planner.staffplanning.liveTab.saveLiveProposal",
            "action": "StaffCloud\Applications\Planner\Modules\StaffPlanning\Http\Controllers\LiveTabController@postSaveLiveProposal"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "profile\/view\/{identifier}",
            "name": "profile.viewProfile",
            "action": "StaffCloud\Applications\Profile\Http\Controllers\ProfileController@getViewProfile"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "recruiting\/abort",
            "name": "recruiting.abort",
            "action": "StaffCloud\Applications\Recruiting\Http\Controllers\RecruitingController@getAbort"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "recruiting\/{pageNumber?}",
            "name": "recruiting.page",
            "action": "StaffCloud\Applications\Recruiting\Http\Controllers\RecruitingController@getPage"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "recruiting\/gallery",
            "name": "recruiting.gallery",
            "action": "StaffCloud\Applications\Recruiting\Http\Controllers\RecruitingController@getGallery"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "teamleader\/projects",
            "name": "teamleader.projects",
            "action": "StaffCloud\Applications\TeamLeader\Http\Controllers\TeamLeaderController@getProjects"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "teamleader\/send-work-data",
            "name": "teamleader.send-work-data",
            "action": "StaffCloud\Applications\TeamLeader\Http\Controllers\TeamLeaderController@postSendWorkData"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "teamleader\/signed-work-data",
            "name": "teamleader.signed-work-data",
            "action": "StaffCloud\Applications\TeamLeader\Http\Controllers\TeamLeaderController@postSendSignedData"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "teamleader\/update-work-data-proposal",
            "name": "teamleader.update-work-data-proposal",
            "action": "StaffCloud\Applications\TeamLeader\Http\Controllers\TeamLeaderController@postUpdateWorkData"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "teamleader\/work-data-proposal\/{id}\/is-edited",
            "name": "teamleader.is-edited-work-data-proposal",
            "action": "StaffCloud\Applications\TeamLeader\Http\Controllers\TeamLeaderController@getIsEditedWorkData"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "teamleader\/projects\/{id}\/assignments",
            "name": "teamleader.projects.assignments",
            "action": "StaffCloud\Applications\TeamLeader\Http\Controllers\TeamLeaderController@getAssignments"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "teamleader\/projects\/{id}\/assignments\/stamps",
            "name": "teamleader.projects.assignments.stamps",
            "action": "StaffCloud\Applications\TeamLeader\Http\Controllers\TeamLeaderController@getEmployeeStamps"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "teamleader\/projects\/{projectId}\/assignments\/{assignmentId}\/checkins",
            "name": "teamleader.projects.assignments.checkins",
            "action": "StaffCloud\Applications\TeamLeader\Http\Controllers\TeamLeaderController@postCreateCheckin"
        },
        {
            "host": null,
            "methods": [
                "POST"
            ],
            "uri": "teamleader\/projects\/{projectId}\/assignments\/{assignmentId}\/edit-checkins",
            "name": "teamleader.projects.assignments.editCheckins",
            "action": "StaffCloud\Applications\TeamLeader\Http\Controllers\TeamLeaderController@postEditCheckins"
        },
        {
            "host": null,
            "methods": [
                "GET",
                "HEAD"
            ],
            "uri": "teamleader\/employee\/{employeeId}\/assignments",
            "name": "teamleader.employee.assignments",
            "action": "StaffCloud\Applications\TeamLeader\Http\Controllers\TeamLeaderController@getEmployeeAssignments"
        }
    ],
    prefix: '',
    route: function route(name, parameters, route) {
        route = route || this.getByName(name);
        if (!route) {
            return undefined;
        }
        return this.toRoute(route, parameters);
    },
    url: function url(url, parameters) {
        parameters = parameters || [];
        var uri = url + '/' + parameters.join('/');
        return this.getCorrectUrl(uri);
    },
    toRoute: function toRoute(route, parameters) {
        var uri = this.replaceNamedParameters(route.uri, parameters);
        var qs = this.getRouteQueryString(parameters);
        if (this.absolute && this.isOtherHost(route)) {
            return '//' + route.host + '/' + uri + qs;
        }
        return this.getCorrectUrl(uri + qs);
    },
    isOtherHost: function isOtherHost(route) {
        return route.host && route.host != window.location.hostname;
    },
    replaceNamedParameters: function replaceNamedParameters(uri, parameters) {
        uri = uri.replace(/\{(.*?)\??\}/g, function(match, key) {
            if (parameters.hasOwnProperty(key)) {
                var value = parameters[key];
                delete parameters[key];
                return value;
            } else {
                return match;
            }
        });
        // Strip out any optional parameters that were not given
        uri = uri.replace(/\/\{.*?\?\}/g, '');
        return uri;
    },
    getRouteQueryString: function getRouteQueryString(parameters) {
        var qs = [];
        for(var key in parameters){
            if (parameters.hasOwnProperty(key)) {
                qs.push(key + '=' + parameters[key]);
            }
        }
        if (qs.length < 1) {
            return '';
        }
        return '?' + qs.join('&');
    },
    getByName: function getByName(name) {
        for(var key in this.routes){
            if (this.routes.hasOwnProperty(key) && this.routes[key].name === name) {
                return this.routes[key];
            }
        }
    },
    getByAction: function getByAction(action) {
        for(var key in this.routes){
            if (this.routes.hasOwnProperty(key) && this.routes[key].action === action) {
                return this.routes[key];
            }
        }
    },
    getCorrectUrl: function getCorrectUrl(uri) {
        var url = this.prefix + '/' + uri.replace(/^\/?/, '');
        if (!this.absolute) {
            return url;
        }
        return this.rootUrl.replace('//?$/', '') + url;
    }
};
var getLinkAttributes = function getLinkAttributes(attributes) {
    if (!attributes) {
        return '';
    }
    var attrs = [];
    for(var key in attributes){
        if (attributes.hasOwnProperty(key)) {
            attrs.push(key + '="' + attributes[key] + '"');
        }
    }
    return attrs.join(' ');
};
var getHtmlLink = function getHtmlLink(url, title, attributes) {
    title = title || url;
    attributes = getLinkAttributes(attributes);
    return '<a href="' + url + '" ' + attributes + '>' + title + '</a>';
};
var laroute = {
    // Generate a url for a given controller action.
    // laroute.action('HomeController@getIndex', [params = {}])
    action: function action(name, parameters) {
        parameters = parameters || {};
        return routes.route(name, parameters, routes.getByAction(name));
    },
    // Generate a url for a given named route.
    // laroute.route('routeName', [params = {}])
    route: function route(route, parameters) {
        parameters = parameters || {};
        return routes.route(route, parameters);
    },
    // Generate a fully qualified URL to the given path.
    // laroute.route('url', [params = {}])
    url: function url(route, parameters) {
        parameters = parameters || {};
        return routes.url(route, parameters);
    },
    // Generate a html link to the given url.
    // laroute.link_to('foo/bar', [title = url], [attributes = {}])
    link_to: function link_to(url, title, attributes) {
        url = laroute.url(url);
        return getHtmlLink(url, title, attributes);
    },
    // Generate a html link to the given route.
    // laroute.link_to_route('route.name', [title=url], [parameters = {}], [attributes = {}])
    link_to_route: function link_to_route(route, title, parameters, attributes) {
        var url = laroute.route(route, parameters);
        return getHtmlLink(url, title, attributes);
    },
    // Generate a html link to the given controller action.
    // laroute.link_to_action('HomeController@getIndex', [title=url], [parameters = {}], [attributes = {}])
    link_to_action: function link_to_action(action, title, parameters, attributes) {
        var url = laroute.action(action, parameters);
        return getHtmlLink(url, title, attributes);
    }
};
export { laroute };
