import { $isAtNodeEnd } from '@lexical/selection';
export function getSelectedNode(selection) {
    var anchor = selection.anchor;
    var focus = selection.focus;
    var anchorNode = selection.anchor.getNode();
    var focusNode = selection.focus.getNode();
    if (anchorNode === focusNode) {
        return anchorNode;
    }
    var isBackward = selection.isBackward();
    if (isBackward) {
        return $isAtNodeEnd(focus) ? anchorNode : focusNode;
    } else {
        return $isAtNodeEnd(anchor) ? anchorNode : focusNode;
    }
}
