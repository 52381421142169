var getCsrfToken = function() {
    var csrfTokenElement = document.getElementsByName('csrf-token')[0];
    return csrfTokenElement ? csrfTokenElement.attributes.content.value : '';
};
var setCsrfToken = function(csrfToken) {
    if (csrfToken) {
        var csrfTokenElement = document.getElementsByName('csrf-token')[0];
        if (csrfTokenElement) {
            csrfTokenElement.attributes.content.value = csrfToken;
        }
    }
};
export { setCsrfToken, getCsrfToken };
