import { getTopWindowDocument } from 'Shared/resources/assets/app/js/helpers/windowHelpers';
import { each } from 'lodash';
export var onDocumentReady = function(callBack) {
    if (document.readyState === 'loading') {
        document.addEventListener('DOMContentLoaded', callBack);
    } else {
        callBack();
    }
};
export var onWindowReady = function(callBack) {
    if (document.readyState === 'complete') {
        callBack();
    } else {
        window.addEventListener('load', callBack);
    }
};
/**
 * Triggers a native event on an DOM element
 *
 * @param {string} event
 * @param {Node}   element
 */ export var trigger = function(event, element) {
    if (event === 'submit' && typeof element.requestSubmit === 'function') {
        return element.requestSubmit();
    }
    return element.dispatchEvent(new Event(event));
};
/**
 * Returns an array of DOM Nodes from a DOM node(s) or a selector
 *
 * @param {Node|Node[]|string} element
 *
 * @returns {Node[]}
 */ var getNodeArrayFromElement = function(element) {
    if (!element || element.length === 0) {
        return [];
    }
    var DOMNodes;
    if (typeof element === 'string') {
        // element is a selector
        DOMNodes = document.querySelectorAll(element);
    } else {
        // element is Node(s)
        DOMNodes = element.length ? element : [
            element
        ];
    }
    if (!DOMNodes.length) return [];
    return Array.from(DOMNodes);
};
/**
 * Add an event listener
 *
 * @param  {String}             event    The event type
 * @param  {Node|Node[]|string} element  The element or the selector to attach the event to (optional, defaults to window)
 * @param  {Function}           callback The callback to run on the event
 * @param  {Boolean}            capture  If true, forces bubbling on non-bubbling events
 */ export var onEvent = function(event, element, callback) {
    var capture = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : false;
    var newElement = element;
    var newCallback = callback;
    var newCapture = capture;
    if (typeof newElement === 'function') {
        newCapture = newCallback;
        newCallback = newElement;
        newElement = window;
    }
    newCapture = Boolean(newCapture);
    var DOMNodes = getNodeArrayFromElement(newElement);
    DOMNodes.forEach(function(DOMNode) {
        return DOMNode.addEventListener(event, newCallback, newCapture);
    });
};
/**
 * Returns the siblings nodes of a DOM element
 *
 * @param  {Node} element
 */ export var siblings = function(element) {
    if (element.parentNode === null) return [];
    return Array.prototype.filter.call(element.parentNode.children, function(child) {
        return child !== element;
    });
};
/**
 * Encode a set of form elements as a string for submission.
 * It returns a text string in standard URL-encoded notation.
 *
 * @param form
 */ export var serialize = function(form) {
    return new URLSearchParams(Array.from(new FormData(form))).toString();
};
export var findOrCreateContainerElement = function(id) {
    var onTopWindowDocument = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : true;
    var containerDocument = onTopWindowDocument ? getTopWindowDocument() : window.document;
    var container = containerDocument.getElementById(id);
    if (!container) {
        container = document.createElement('div');
        container.setAttribute('id', id);
        containerDocument.body.appendChild(container);
    }
    return container;
};
export var getOffsetRect = function getOffsetRect(domElement) {
    var boundingClientRect = domElement.getBoundingClientRect().toJSON();
    boundingClientRect.left = boundingClientRect.left + window.scrollX;
    boundingClientRect.top = boundingClientRect.top + window.scrollY;
    boundingClientRect.right = boundingClientRect.right + window.scrollX;
    boundingClientRect.bottom = boundingClientRect.bottom + window.scrollY;
    boundingClientRect.x = boundingClientRect.x === undefined ? boundingClientRect.left : boundingClientRect.x + window.scrollX;
    boundingClientRect.y = boundingClientRect.y === undefined ? boundingClientRect.top : boundingClientRect.y + window.scrollY;
    return boundingClientRect;
};
export var toggleCssClasses = function toggleCssClasses(htmlElement) {
    for(var _len = arguments.length, cssClasses = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++){
        cssClasses[_key - 1] = arguments[_key];
    }
    each(cssClasses, function(cssClass) {
        each(cssClass.split(' '), function(token) {
            if (htmlElement.classList.contains(token)) {
                htmlElement.classList.remove(token);
            } else {
                htmlElement.classList.add(token);
            }
        });
    });
};
