function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import { useQuery } from '@tanstack/react-query';
import { notification } from 'antd';
import { ts } from 'Shared/resources/assets/app/js/helpers/i18nHelpers';
import { Translator } from 'Shared/resources/assets/app/js/utils/i18n/Translator';
var getCurrentControllerAction = function() {
    var url = window.location.href;
    var parsedUrl = new URL(url);
    var pathname = parsedUrl.pathname;
    var pathWithoutLeadingSlash = pathname.substring(1);
    return pathWithoutLeadingSlash;
};
var useConfigQuery = function() {
    var _config_app;
    var staleTime = 60 * 60 * 1000; // 1 hour
    var module = getCurrentControllerAction();
    var url = "/config?module=".concat(encodeURIComponent(module));
    var query = useQuery({
        queryKey: [
            'config'
        ],
        queryFn: function() {
            return fetch(url).then(function(res) {
                return res.json();
            });
        },
        staleTime: staleTime
    });
    var isError = query.isError, error = query.error, tmp = query.data, _ref = tmp === void 0 ? {} : tmp, tmp1 = _ref.response, config = (tmp1 === void 0 ? {} : tmp1).config;
    if (isError) {
        notification.error({
            message: ts('Error fetching app config'),
            description: error.message,
            placement: 'bottomRight'
        });
    }
    if (config === null || config === void 0 ? void 0 : (_config_app = config.app) === null || _config_app === void 0 ? void 0 : _config_app.translations) {
        Translator.addTranslations(config.app.translations);
    }
    return _object_spread_props(_object_spread({}, query), {
        config: config
    });
};
export { useConfigQuery };
