import { App } from 'Shared/resources/assets/app/js/App';
import { getTopWindowDocument } from 'Shared/resources/assets/app/js/helpers/windowHelpers';
var Event = {
    listen: function listen(name) {
        var callback = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : function() {}, keepUniqueCallbacks = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : false;
        var map = this.getEventsMap();
        map[name] || (map[name] = []);
        if (keepUniqueCallbacks) {
            this.stopListening(name, callback);
        }
        map[name].push(callback);
    },
    stopListening: function stopListening(name, callback) {
        var map = this.getEventsMap();
        map[name] = map[name].filter(function(item) {
            return item.toString() !== callback.toString();
        });
    },
    pop: function pop(name) {
        var map = this.getEventsMap();
        var lastItem = map[name][map[name].length - 1];
        map[name] = map[name].slice(0, map[name].length - 1);
        return lastItem;
    },
    fire: function fire(name) {
        var data = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : null;
        var map = this.getEventsMap();
        if (!map[name]) {
            return;
        }
        map[name].forEach(function(callback) {
            callback(data);
        });
    },
    getEventsMap: function getEventsMap() {
        var topWindowDocument = getTopWindowDocument();
        if (!topWindowDocument.hasOwnProperty('appCustomEventsMap')) {
            topWindowDocument.appCustomEventsMap = {};
        }
        return topWindowDocument.appCustomEventsMap;
    }
};
App.Event = Event;
export { Event };
