function _instanceof(left, right) {
    if (right != null && typeof Symbol !== "undefined" && right[Symbol.hasInstance]) {
        return !!right[Symbol.hasInstance](left);
    } else {
        return left instanceof right;
    }
}
import { get } from 'lodash';
import dayjs from 'dayjs';
import { removeAccents } from 'Shared/resources/assets/app/js/helpers/stringHelpers';
import { tp } from 'Shared/resources/assets/app/js/helpers/i18nHelpers';
export var sortColumn = function(a, b, column) {
    if (!a || !get(a, column)) {
        return -1;
    }
    if (_instanceof(get(a, column), Date) && !isNaN(get(a, column))) {
        return get(a, column) - get(b, column);
    }
    if (typeof get(a, column) === 'string') {
        if (dayjs(get(a, column)).isValid() && dayjs(get(b, column)).isValid()) {
            return dayjs(get(a, column)) - dayjs(get(b, column));
        }
        var _ref;
        return get(a, column).localeCompare((_ref = b ? get(b, column) : null) !== null && _ref !== void 0 ? _ref : '', undefined, {
            sensitivity: 'base'
        });
    }
    if (typeof get(a, column) === 'number') {
        var _ref1;
        return get(a, column) - ((_ref1 = b ? get(b, column) : null) !== null && _ref1 !== void 0 ? _ref1 : Number.NEGATIVE_INFINITY);
    }
};
/**
 * Filters 'collection' based on the presence of the 'filter' param in any of the 'fieldsToFilter'
 */ export var applyGlobalFilter = function() {
    var collection = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : [], filter = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : '', fieldsToFilter = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : [];
    var normalizedFilter = removeAccents(filter).toLowerCase();
    return collection.length && filter.length && fieldsToFilter.length ? collection.filter(function(item) {
        return fieldsToFilter.map(function(fieldToFilter) {
            return typeof item[fieldToFilter] === 'string' || _instanceof(item[fieldToFilter], String) ? removeAccents(item[fieldToFilter]).toLowerCase() : '';
        }).some(function(fieldToFilter) {
            return fieldToFilter.includes(normalizedFilter);
        });
    }) : collection;
};
export var getDefaultPaginationPageSize = function() {
    return 20;
};
export var getDefaultPaginationProps = function(count) {
    var defaultPageSize = getDefaultPaginationPageSize();
    return count > defaultPageSize ? {
        showTotal: function(total) {
            return tp('Total 1 item|Total %1 items', total, [
                total
            ]);
        },
        defaultPageSize: defaultPageSize,
        showSizeChanger: true,
        pageSizeOptions: Array.from(new Set([
            10,
            20,
            50,
            100,
            defaultPageSize
        ]))
    } : false;
};
