function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_with_holes(arr) {
    if (Array.isArray(arr)) return arr;
}
function _iterable_to_array_limit(arr, i) {
    var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];
    if (_i == null) return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _s, _e;
    try {
        for(_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true){
            _arr.push(_s.value);
            if (i && _arr.length === i) break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally{
        try {
            if (!_n && _i["return"] != null) _i["return"]();
        } finally{
            if (_d) throw _e;
        }
    }
    return _arr;
}
function _non_iterable_rest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _sliced_to_array(arr, i) {
    return _array_with_holes(arr) || _iterable_to_array_limit(arr, i) || _unsupported_iterable_to_array(arr, i) || _non_iterable_rest();
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
import { Translator } from 'Shared/resources/assets/app/js/utils/i18n/Translator';
import { i18nMain } from 'Shared/resources/assets/app/js/utils/i18n/i18nMain';
import { config } from './configHelpers';
/**
 * Helper for single translations
 *
 * Usage example:
 * ts('My string %1 to be %2 translated', [firstValue, secondValue]);
 *
 * @param {string} string        String to be translated
 * @param {Array}  [params = []] Parameters to be replaced
 *
 * @return {string} Translated string
 */ export var ts = function ts(string) {
    var params = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : [];
    return Translator.translate(string, params);
};
/**
 * Helper for plural translations
 *
 * Usage example:
 * tp('I have a %1 dog|I have multiple %1 dogs', dogs.length, 'small');
 *
 * @param {string} string        String to be translated
 * @param {int}    quantity      Quantity
 * @param {object} [params = []] Parameters to be replaced
 *
 * @return {string} Translated string
 */ export var tp = function tp(string, quantity) {
    var params = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : [];
    return Translator.translatePlural(string, quantity, params);
};
export var i18n = function i18n(value) {
    i18nMain.init();
    i18nMain.setValue(value);
    return i18nMain;
};
export var unformatNumber = function unformatNumber(number) {
    var numberFormat = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : null;
    number = number.trim();
    if (number.length === 0) {
        return number;
    }
    var _ref = _sliced_to_array(numberFormat !== null && numberFormat !== void 0 ? numberFormat : config('app.i18n', {
        number: [
            '',
            '.'
        ]
    }).number, 2), thousandsSeparator = _ref[0], decimalsSeparator = _ref[1];
    return number.split(thousandsSeparator).join('').replace(decimalsSeparator, '.');
};
