export var isParentSameDomain = function isParentSameDomain() {
    try {
        var doc = window.parent.document;
        if (!doc) {
            throw new Error('The parent is not accessible');
        }
        return true;
    } catch (e) {
        return false;
    }
};
export var getTopWindowDocument = function getTopWindowDocument() {
    if (window.location.hostname.indexOf('office') !== -1) {
        return window.document;
    }
    return isParentSameDomain() ? window.top.document : window.document;
};
export var isIFrame = function isIFrame() {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
};
