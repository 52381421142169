import { Config } from 'Shared/resources/assets/app/js/utils/Config';
export var config = function config(key, defaultValue) {
    return Config.get(key, defaultValue);
};
export var getApplicationAndModuleFromUrl = function() {
    var url = window.location.pathname;
    var urlParts = url.split('/');
    var application = urlParts[1] === 'auth' ? 'authentication' : urlParts[1];
    var module = urlParts[1] === 'auth' ? null : urlParts[2];
    return {
        application: application,
        module: module
    };
};
