function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_with_holes(arr) {
    if (Array.isArray(arr)) return arr;
}
function _instanceof(left, right) {
    if (right != null && typeof Symbol !== "undefined" && right[Symbol.hasInstance]) {
        return !!right[Symbol.hasInstance](left);
    } else {
        return left instanceof right;
    }
}
function _iterable_to_array_limit(arr, i) {
    var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];
    if (_i == null) return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _s, _e;
    try {
        for(_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true){
            _arr.push(_s.value);
            if (i && _arr.length === i) break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally{
        try {
            if (!_n && _i["return"] != null) _i["return"]();
        } finally{
            if (_d) throw _e;
        }
    }
    return _arr;
}
function _non_iterable_rest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _sliced_to_array(arr, i) {
    return _array_with_holes(arr) || _iterable_to_array_limit(arr, i) || _unsupported_iterable_to_array(arr, i) || _non_iterable_rest();
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $wrapNodeInElement, mergeRegister } from '@lexical/utils';
import { $createParagraphNode, $createRangeSelection, $getSelection, $insertNodes, $isNodeSelection, $isRootOrShadowRoot, $setSelection, COMMAND_PRIORITY_EDITOR, COMMAND_PRIORITY_HIGH, COMMAND_PRIORITY_LOW, createCommand, DRAGOVER_COMMAND, DRAGSTART_COMMAND, DROP_COMMAND } from 'lexical';
import { CAN_USE_DOM } from 'Shared/resources/assets/app/js/ui/RichTextEditor/utils/canUseDOM';
import { $createImageNode, $isImageNode, ImageNode } from 'Shared/resources/assets/app/js/ui/RichTextEditor/nodes/ImageNode/ImageNode.jsx';
var getDOMSelection = function(targetWindow) {
    return CAN_USE_DOM ? (targetWindow || window).getSelection() : null;
};
export var INSERT_IMAGE_COMMAND = createCommand('INSERT_IMAGE_COMMAND');
function ImagesPlugin(param) {
    var captionsEnabled = param.captionsEnabled;
    var _useLexicalComposerContext = _sliced_to_array(useLexicalComposerContext(), 1), editor = _useLexicalComposerContext[0];
    useEffect(function() {
        if (!editor.hasNodes([
            ImageNode
        ])) {
            throw new Error('ImagesPlugin: ImageNode not registered on editor');
        }
        return mergeRegister(editor.registerCommand(INSERT_IMAGE_COMMAND, function(payload) {
            var imageNode = $createImageNode(payload);
            $insertNodes([
                imageNode
            ]);
            if ($isRootOrShadowRoot(imageNode.getParentOrThrow())) {
                $wrapNodeInElement(imageNode, $createParagraphNode).selectEnd();
            }
            return true;
        }, COMMAND_PRIORITY_EDITOR), editor.registerCommand(DRAGSTART_COMMAND, function(event) {
            return $onDragStart(event);
        }, COMMAND_PRIORITY_HIGH), editor.registerCommand(DRAGOVER_COMMAND, function(event) {
            return $onDragover(event);
        }, COMMAND_PRIORITY_LOW), editor.registerCommand(DROP_COMMAND, function(event) {
            return $onDrop(event, editor);
        }, COMMAND_PRIORITY_HIGH));
    }, [
        captionsEnabled,
        editor
    ]);
    return null;
}
var TRANSPARENT_IMAGE = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
var img = document.createElement('img');
img.src = TRANSPARENT_IMAGE;
function $onDragStart(event) {
    var node = $getImageNodeInSelection();
    if (!node) {
        return false;
    }
    var dataTransfer = event.dataTransfer;
    if (!dataTransfer) {
        return false;
    }
    dataTransfer.setData('text/plain', '_');
    dataTransfer.setDragImage(img, 0, 0);
    dataTransfer.setData('application/x-lexical-drag', JSON.stringify({
        data: {
            altText: node.__altText,
            caption: node.__caption,
            height: node.__height,
            key: node.getKey(),
            maxWidth: node.__maxWidth,
            showCaption: node.__showCaption,
            src: node.__src,
            width: node.__width
        },
        type: 'image'
    }));
    return true;
}
function $onDragover(event) {
    var node = $getImageNodeInSelection();
    if (!node) {
        return false;
    }
    if (!canDropImage(event)) {
        event.preventDefault();
    }
    return true;
}
function $onDrop(event, editor) {
    var node = $getImageNodeInSelection();
    if (!node) {
        return false;
    }
    var data = getDragImageData(event);
    if (!data) {
        return false;
    }
    event.preventDefault();
    if (canDropImage(event)) {
        var range = getDragSelection(event);
        node.remove();
        var rangeSelection = $createRangeSelection();
        if (range !== null && range !== undefined) {
            rangeSelection.applyDOMRange(range);
        }
        $setSelection(rangeSelection);
        editor.dispatchCommand(INSERT_IMAGE_COMMAND, data);
    }
    return true;
}
function $getImageNodeInSelection() {
    var selection = $getSelection();
    if (!$isNodeSelection(selection)) {
        return null;
    }
    var nodes = selection.getNodes();
    var node = nodes[0];
    return $isImageNode(node) ? node : null;
}
function getDragImageData(event) {
    var _event_dataTransfer;
    var dragData = (_event_dataTransfer = event.dataTransfer) === null || _event_dataTransfer === void 0 ? void 0 : _event_dataTransfer.getData('application/x-lexical-drag');
    if (!dragData) {
        return null;
    }
    var _JSON_parse = JSON.parse(dragData), type = _JSON_parse.type, data = _JSON_parse.data;
    if (type !== 'image') {
        return null;
    }
    return data;
}
function canDropImage(event) {
    var target = event.target;
    return !!(target && _instanceof(target, HTMLElement) && !target.closest('code, span.editor-image'));
}
function getDragSelection(event) {
    var range;
    var target = event.target;
    var targetWindow = target == null ? null : target.nodeType === 9 ? target.defaultView : target.ownerDocument.defaultView;
    var domSelection = getDOMSelection(targetWindow);
    if (document.caretRangeFromPoint) {
        range = document.caretRangeFromPoint(event.clientX, event.clientY);
    } else if (event.rangeParent && domSelection !== null) {
        domSelection.collapse(event.rangeParent, event.rangeOffset || 0);
        range = domSelection.getRangeAt(0);
    } else {
        throw Error("Cannot get the selection when dragging");
    }
    return range;
}
ImagesPlugin.propTypes = {
    captionsEnabled: PropTypes.bool
};
export { ImagesPlugin };
