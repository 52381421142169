var VERTICAL_GAP = 10;
var HORIZONTAL_OFFSET = 5;
export function setFloatingElemPositionForLinkEditor(targetRect, floatingElem, anchorElem) {
    var verticalGap = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : VERTICAL_GAP, horizontalOffset = arguments.length > 4 && arguments[4] !== void 0 ? arguments[4] : HORIZONTAL_OFFSET;
    var scrollerElem = anchorElem.parentElement;
    if (targetRect === null || !scrollerElem) {
        floatingElem.style.opacity = '0';
        floatingElem.style.transform = 'translate(-10000px, -10000px)';
        return;
    }
    var floatingElemRect = floatingElem.getBoundingClientRect();
    var anchorElementRect = anchorElem.getBoundingClientRect();
    var editorScrollerRect = scrollerElem.getBoundingClientRect();
    var top = targetRect.top - verticalGap;
    var left = targetRect.left - horizontalOffset;
    if (top < editorScrollerRect.top) {
        top += floatingElemRect.height + targetRect.height + verticalGap * 2;
    }
    if (left + floatingElemRect.width > editorScrollerRect.right) {
        left = editorScrollerRect.right - floatingElemRect.width - horizontalOffset;
    }
    top -= anchorElementRect.top;
    left -= anchorElementRect.left;
    floatingElem.style.opacity = '1';
    floatingElem.style.transform = "translate(".concat(left, "px, ").concat(top, "px)");
}
