function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
import { defaults, isEmpty } from 'lodash';
export var parseQueryString = function parseQueryString(queryString) {
    var data = {};
    if (isEmpty(queryString)) {
        return data;
    }
    queryString.split('&').forEach(function(pair) {
        var parts = pair.split('=');
        data[parts[0]] = parts[1];
    });
    return data;
};
export var buildQueryString = function buildQueryString(parameters) {
    return Object.keys(parameters).map(function(key) {
        return encodeURIComponent(key) + '=' + encodeURIComponent(parameters[key]);
    }).join('&');
};
export var getHashAttributes = function getHashAttributes() {
    return parseQueryString(location.hash.slice(1));
};
export var getHashAttribute = function getHashAttribute(key) {
    return parseQueryString(location.hash.slice(1))[key];
};
export var setHashAttributes = function setHashAttributes(parameters) {
    location.hash = buildQueryString(parameters);
};
export var updateHashAttributes = function updateHashAttributes(parameters) {
    setHashAttributes(defaults(parameters, getHashAttributes()));
};
export var setHashAttribute = function setHashAttribute(key, value) {
    updateHashAttributes(_define_property({}, key, value));
};
export var getQueryAttributes = function getQueryAttributes() {
    return parseQueryString(location.search.slice(1));
};
export var getSearchParams = function() {
    return Object.fromEntries(new URLSearchParams(window.location.search));
};
export var isValidUrl = function(url) {
    // Source: https://stackoverflow.com/a/8234912/2013580
    var urlRegExp = new RegExp(/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/);
    return urlRegExp.test(url);
};
export function sanitizeUrl(url) {
    var supportedProtocols = new Set([
        'http:',
        'https:',
        'mailto:',
        'sms:',
        'tel:'
    ]);
    try {
        var parsedUrl = new URL(url);
        if (!supportedProtocols.has(parsedUrl.protocol)) {
            return 'about:blank';
        }
    } catch (e) {
        return url;
    }
    return url;
}
