import { Event } from 'Shared/resources/assets/app/js/utils/Event';
export var listen = function listen() {
    var _Event;
    return (_Event = Event).listen.apply(_Event, arguments);
};
export var stopListening = function stopListening() {
    var _Event;
    return (_Event = Event).stopListening.apply(_Event, arguments);
};
export var popListener = function popListener() {
    var _Event;
    return (_Event = Event).pop.apply(_Event, arguments);
};
export var fire = function fire() {
    var _Event;
    return (_Event = Event).fire.apply(_Event, arguments);
};
