import { getTopWindowDocument } from 'Shared/resources/assets/app/js/helpers/windowHelpers';
export var getNotifier = function getNotifier() {
    return getTopWindowDocument().App.Notification;
};
export var alert = function alert() {
    var _getNotifier;
    return (_getNotifier = getNotifier()).alert.apply(_getNotifier, arguments);
};
export var confirm = function confirm() {
    var _getNotifier;
    return (_getNotifier = getNotifier()).confirm.apply(_getNotifier, arguments);
};
export var success = function success() {
    var _getNotifier;
    return (_getNotifier = getNotifier()).success.apply(_getNotifier, arguments);
};
export var error = function error() {
    var _getNotifier;
    return (_getNotifier = getNotifier()).error.apply(_getNotifier, arguments);
};
export var info = function info() {
    var _getNotifier;
    return (_getNotifier = getNotifier()).info.apply(_getNotifier, arguments);
};
export var warning = function warning() {
    var _getNotifier;
    return (_getNotifier = getNotifier()).warning.apply(_getNotifier, arguments);
};
export var prompt = function prompt() {
    var _getNotifier;
    return (_getNotifier = getNotifier()).prompt.apply(_getNotifier, arguments);
};
export var notify = function notify() {
    var _getNotifier;
    return (_getNotifier = getNotifier()).notify.apply(_getNotifier, arguments);
};
