import $ from 'jquery';
import { App } from 'Shared/resources/assets/app/js/App';
import { ts } from 'Shared/resources/assets/app/js/helpers/i18nHelpers';
import { onDocumentReady } from 'Shared/resources/assets/app/js/helpers/domHelpers';
import { isRecruitingApp } from 'Shared/resources/assets/app/js/helpers/appHelpers';
import { confirm } from 'Shared/resources/assets/app/js/helpers/notificationHelpers';
import { FormBuilder } from 'Shared/resources/assets/app/js/ui/libs';
import { laroute } from 'Shared/resources/assets/app/js/laroute';
var Recruiting = {
    /**
     * A boolean to know whether a page unload event is triggered by not pressing one of the recruiting form buttons.
     *
     * By default, in the recruiting form we always show the leave prompt, unless the user clicks our form buttons.
     */ warnBeforeUnload: true,
    /**
     * Initialize recruiting.
     */ init: function init(currentLanguageId) {
        this.cacheElements();
        this.bindEvents(currentLanguageId);
    },
    /**
     * Cache elements.
     */ cacheElements: function cacheElements() {
        this.$app = $('#lt-recruiting');
        this.$pageNumber = this.$app.find('#pageNumber');
        this.$header = this.$app.find('#lt-header');
        this.$languageDropdown = this.$header.find('#lang');
        this.$abortButton = this.$app.find('#abort-button');
        this.$formButtons = this.$app.find('[class*=ui-button]');
    },
    /**
     * Bind events.
     */ bindEvents: function bindEvents(currentLanguageId) {
        this.$languageDropdown.on('change', (function() {
            this.changeLanguage();
        }).bind(this));
        this.$abortButton.on('click', (function(e) {
            e.preventDefault();
            confirm(ts('Are you sure you want to abort your application? This will delete all of your data on file.'), function() {
                window.location.href = laroute.route('recruiting.abort');
            });
        }).bind(this));
        this.$formButtons.on('click', (function(e) {
            this.warnBeforeUnload = false;
        }).bind(this));
        if (this.$formButtons.length === 0) {
            // For the case when the user has completed the recruiting form
            this.warnBeforeUnload = false;
        }
        this.$app.find('[data-identifier=communication_language]').each(function() {
            if ($(this).val() === '') {
                $(this).val(currentLanguageId);
            }
        });
    },
    /**
     * Get the current form page number.
     *
     * @returns {int}
     */ getCurrentPage: function getCurrentPage() {
        return parseInt(this.$pageNumber.val());
    },
    /**
     * Change recruiting app language.
     */ changeLanguage: function changeLanguage() {
        window.location.href = laroute.route('recruiting.page', {
            pageNumber: this.getCurrentPage(),
            lang: this.$languageDropdown.val()
        });
    },
    /**
     * Recruiting app custom onBeforeUnload event.
     */ onBeforeUnload: function onBeforeUnload() {
        var _this = this;
        if (!isRecruitingApp()) {
            return;
        }
        var onBeforeUnloadCallback = function(e) {
            if (_this.warnBeforeUnload === true) {
                // Custom text support is removed https://developer.mozilla.org/en-US/docs/Web/Events/beforeunload#Browser_compatibility
                // However e.returnValue and what this callback return must be the same for the browser to show an alert.
                var message = ts('You have unsaved changes! Are you sure you want to leave this page?');
                e.returnValue = message;
                return message;
            }
        };
        // https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#Parameters
        var options = {
            capture: true,
            useCapture: true
        };
        window.addEventListener('beforeunload', onBeforeUnloadCallback, options);
    }
};
onDocumentReady(function() {
    // Mounting the custom onBeforeUnload functionality for the recruiting app
    Recruiting.onBeforeUnload();
    FormBuilder.preventLeavePageWarningForFormSubmits();
});
App.Recruiting = Recruiting;
export { Recruiting };
