// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/css-loader@7.1.2_@rspack+core@1.1.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/.pnpm/css-loader@7.1.2_@rspack+core@1.1.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.history-icon--KaCk2:hover,.delete-icon--KmjVq:hover{color:#2e97e5}`, "",{"version":3,"sources":["webpack://./app/Shared/resources/assets/app/js/ui/AntDesign/FileUploadHistory/FileUploadHistory.module.scss","webpack://./app/Shared/resources/assets/app/sass/design-definition.scss"],"names":[],"mappings":"AAEA,qDAEI,aCoCY","sourcesContent":[".history-icon:hover,.delete-icon:hover{color:#2e97e5}",null],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"historyIcon": `history-icon--KaCk2`,
	"deleteIcon": `delete-icon--KmjVq`
};
export default ___CSS_LOADER_EXPORT___;
