import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { HorizontalRuleNode } from '@lexical/react/LexicalHorizontalRuleNode';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { ImageNode } from 'Shared/resources/assets/app/js/ui/RichTextEditor/nodes/ImageNode/ImageNode';
import { CustomAttributeNode } from 'Shared/resources/assets/app/js/ui/RichTextEditor/nodes/CustomAttributeNode/CustomAttributeNode';
import { CustomTextNode } from 'Shared/resources/assets/app/js/ui/RichTextEditor/nodes/CustomTextNode';
var EditorNodes = [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    CodeHighlightNode,
    AutoLinkNode,
    LinkNode,
    HorizontalRuleNode,
    ImageNode,
    CustomAttributeNode,
    CustomTextNode
];
export { EditorNodes };
