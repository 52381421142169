import { App } from 'Shared/resources/assets/app/js/App';
import Cookies from 'js-cookie';
import { fire } from 'Shared/resources/assets/app/js/helpers/eventHelpers';
import { debounce, isBoolean } from 'lodash';
var Sidebar = {
    /**
     * The minimum window width for an open sidebar.
     */ MIN_WIDTH: 1500,
    /**
     * State for open sidebar.
     */ STATE_OPEN: 'open',
    /**
     * State for closed sidebar.
     */ STATE_CLOSED: 'closed',
    /**
     * The event which will be fired on toggle.
     */ TOGGLE_EVENT: 'planner.sidebar.toggle',
    /**
     * Initialize sidebar.
     */ init: function init() {
        if (!this.getContainer()) {
            return;
        }
        this.toggle();
        window.addEventListener('resize', debounce(this.toggle.bind(this), 100));
    },
    /**
     * Returns the jQuery object for the sidebar.
     *
     * @returns {object}
     */ getContainer: function getContainer() {
        return document.getElementById('lt-left');
    },
    /**
     * Returns the current state of the sidebar.
     *
     * @returns {string}
     */ getState: function getState() {
        var _this_getContainer;
        return ((_this_getContainer = this.getContainer()) === null || _this_getContainer === void 0 ? void 0 : _this_getContainer.classList.contains('lt-left-collapsed')) ? this.STATE_CLOSED : this.STATE_OPEN;
    },
    /**
     * Sets the state of the sidebar.
     *
     * @param {string} state State to be applied.
     */ setState: function setState(state) {
        if (state === this.STATE_OPEN) {
            this.getContainer().classList.remove('lt-left-collapsed');
            document.body.classList.remove('left-menu-collapsed');
        } else if (state === this.STATE_CLOSED) {
            this.getContainer().classList.add('lt-left-collapsed');
            document.body.classList.add('left-menu-collapsed');
        } else {
            throw 'Invalid state';
        }
        fire(this.TOGGLE_EVENT);
        this.saveState(state);
    },
    /**
     * Loads the previous state from local storage or cookie.
     *
     * @returns {bool|string} False if no previous state found.
     */ loadState: function loadState() {
        if (localStorage && localStorage.getItem('sidebar-state')) {
            return localStorage.getItem('sidebar-state');
        }
        if (Cookies.get('sidebar-state')) {
            return Cookies.get('sidebar-state');
        }
        return false;
    },
    /**
     * Stores the current state in local storage and cookie.
     */ saveState: function saveState(state) {
        if (localStorage) {
            localStorage.setItem('sidebar-state', state);
        }
        Cookies.set('sidebar-state', state, {
            expires: 365
        });
    },
    /**
     * Performs the DOM toggle action.
     *
     * If window width is below the minimum, it will always close the sidebar.
     *
     * @param {boolean} force If true, window width will be ignored.
     */ toggle: function toggle(force) {
        force = isBoolean(force) ? force : false;
        if (!force && window.innerWidth < this.MIN_WIDTH) {
            return this.setState(this.STATE_CLOSED);
        }
        if (force || this.getState() !== this.loadState()) {
            if (this.getState() === this.STATE_CLOSED) {
                this.setState(this.STATE_OPEN);
            } else {
                this.setState(this.STATE_CLOSED);
            }
        }
    }
};
try {
    if (window.self === window.top) {
        Sidebar.init();
    }
} catch (e) {}
App.Sidebar = Sidebar;
export { Sidebar };
