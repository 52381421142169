function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_with_holes(arr) {
    if (Array.isArray(arr)) return arr;
}
function _iterable_to_array_limit(arr, i) {
    var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];
    if (_i == null) return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _s, _e;
    try {
        for(_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true){
            _arr.push(_s.value);
            if (i && _arr.length === i) break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally{
        try {
            if (!_n && _i["return"] != null) _i["return"]();
        } finally{
            if (_d) throw _e;
        }
    }
    return _arr;
}
function _non_iterable_rest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _sliced_to_array(arr, i) {
    return _array_with_holes(arr) || _iterable_to_array_limit(arr, i) || _unsupported_iterable_to_array(arr, i) || _non_iterable_rest();
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { $createParagraphNode, $getRoot, $insertNodes, createCommand } from 'lexical';
import { $generateNodesFromDOM } from '@lexical/html';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
export var INITIALIZE_HTML_COMMAND = createCommand();
var makeSureCustomAttributesAreParagraphWrapped = function(dom) {
    var spans = dom.querySelectorAll('span.editor-attribute[contenteditable="false"]');
    spans.forEach(function(span) {
        if (span.parentElement.tagName.toLowerCase() !== 'p') {
            var pTag = document.createElement('p');
            span.parentElement.insertBefore(pTag, span);
            pTag.appendChild(span);
        }
    });
    return dom;
};
var LexicalDefaultValuePlugin = function(param) {
    var _param_value = param.value, value = _param_value === void 0 ? '' : _param_value;
    var _useLexicalComposerContext = _sliced_to_array(useLexicalComposerContext(), 1), editor = _useLexicalComposerContext[0];
    var _useState = _sliced_to_array(useState(false), 2), initialized = _useState[0], setInitialized = _useState[1];
    useEffect(function() {
        // https://github.com/facebook/lexical/issues/4474#issuecomment-1558485693
        // Set the editor to not editable - it won't take focus while in that state
        editor.setEditable(false);
        var unregister = editor.registerCommand(INITIALIZE_HTML_COMMAND, function(payload) {
            var parser = new DOMParser();
            var dom = parser.parseFromString(payload, 'text/html');
            var domWithFixedCustomAttributes = makeSureCustomAttributesAreParagraphWrapped(dom);
            var nodes = $generateNodesFromDOM(editor, domWithFixedCustomAttributes);
            editor.update(function() {
                var root = $getRoot();
                root.clear();
                $insertNodes(nodes);
                if (root.isEmpty()) {
                    var paragraph = $createParagraphNode();
                    root.append(paragraph);
                }
            });
            setInitialized(true);
            return true;
        }, 0);
        // Set the editor back to editable a frame later
        requestAnimationFrame(function() {
            editor.setEditable(true);
        });
        return unregister;
    }, [
        editor
    ]);
    useEffect(function() {
        !initialized && value && editor.dispatchCommand(INITIALIZE_HTML_COMMAND, value);
    }, [
        editor,
        initialized,
        value
    ]);
    return null;
};
LexicalDefaultValuePlugin.propTypes = {
    value: PropTypes.string
};
export { LexicalDefaultValuePlugin };
